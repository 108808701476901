import React, {Component} from 'react';
import "../styles/background.css";

export default class Background extends Component{
    constructor(props){
        super(props);
        this.state={
            Leaf_4_int:
                ["M314.896 442.661L289.528 310.592C289.528 310.592 378.837 372.007 314.896 442.661Z",
                "M314.896 442.661L289.528 310.592C289.528 310.592 229.595 401.874 314.896 442.661Z"],
            Leaf_3_int:
                ["M370.128 522.418L145.951 364.458C145.951 364.458 364.533 326.126 370.128 522.418Z",
                "M370.128 522.418L145.951 364.458C145.951 364.458 186.494 585.05 370.128 522.418Z"],
            Leaf_2_int:
                ["M624.046 536.757L764.009 311.047C764.009 311.047 811.006 522.893 624.046 536.757Z",
                "M624.046 536.757L764.009 311.047C764.009 311.047 554.994 359.961 624.046 536.757Z"],
            Leaf_1_int:
                ["M620.552 502.79L798.09 496.631C798.09 496.631 698.476 602.818 620.552 502.79Z",
                "M620.552 502.79L798.09 496.631C798.09 496.631 692.531 397.654 620.552 502.79Z"],
            Stars_int:
                ["M738 168H731.222C718.09 168 707.5 157.236 707.5 143.889V137V143.889C707.5 157.236 696.91 168 683.778 168H677H683.778C696.91 168 707.5 178.764 707.5 192.111V199V192.111C707.5 178.764 718.09 168 731.222 168H738Z",
                "M205 98H201.556C194.882 98 189.5 92.4444 189.5 85.5556V82V85.5556C189.5 92.4444 184.118 98 177.444 98H174H177.444C184.118 98 189.5 103.556 189.5 110.444V114V110.444C189.5 103.556 194.882 98 201.556 98H205Z",
                "M251 285.5H243.889C230.111 285.5 219 274.215 219 260.222V253V260.222C219 274.215 207.889 285.5 194.111 285.5H187H194.111C207.889 285.5 219 296.785 219 310.778V318V310.778C219 296.785 230.111 285.5 243.889 285.5H251Z",
                "M621 97.7368H617.579C611.263 97.7368 606 92.4737 606 86.1579V83V86.4211C606 92.7368 600.737 98 594.421 98H591H594.421C600.737 98 606 103.263 606 109.579V113V109.316C606 103 611.263 97.7368 617.579 97.7368H621Z",
                "M722 297H716.526C706.421 297 698 288.579 698 278.474V273V278.474C698 288.579 689.579 297 679.474 297H674H679.474C689.579 297 698 305.421 698 315.526V321V315.526C698 305.421 706.421 297 716.526 297H722Z",
                "M338 164.5H332.231C321.654 164.5 313 155.673 313 144.885V139V144.885C313 155.673 304.346 164.5 293.769 164.5H288H293.769C304.346 164.5 313 173.327 313 184.115V190V184.115C313 173.327 321.654 164.5 332.231 164.5H338Z",
                "M472 82H466.168C454.991 82 446 73.0093 446 61.8318V56V61.8318C446 73.0093 437.009 82 425.832 82H420H425.832C437.009 82 446 90.9907 446 102.168V108V102.168C446 90.9907 454.991 82 466.168 82H472Z"]
        }
    }
    render(){return(
        <svg className="bodyBackground" viewBox="0 0 881 794" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g id="Background">
                    <path id="Vector 1" d="M20.7027 159.358C-17.3546 103.493 8.70547 0 8.70547 0H881V756.319C881 756.319 793.021 827.651 729.536 774.026C666.051 720.401 729.536 644.516 729.536 603.032C729.536 514.999 671.045 477.047 607.564 416.861C524.822 338.412 460.099 339.458 361.622 281.28C302.413 246.3 238.557 265.56 170.667 252.95C113.473 242.326 56.6715 212.157 20.7027 159.358Z" fill="#FF7E62"/>
                    <g id="Stars">
                        <path id="Vector" d="M732.806 168.33H727.214C716.379 168.33 707.641 159.489 707.641 148.527V142.869V148.527C707.641 159.489 698.903 168.33 688.068 168.33H682.476H688.068C698.903 168.33 707.641 177.171 707.641 188.134V193.792V188.134C707.641 177.171 716.379 168.33 727.214 168.33H732.806Z" fill="url(#paint0_linear)">
                            <animate dur="0.5s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M732.806 168.33H727.214C716.379 168.33 707.641 159.489 707.641 148.527V142.869V148.527C707.641 159.489 698.903 168.33 688.068 168.33H682.476H688.068C698.903 168.33 707.641 177.171 707.641 188.134V193.792V188.134C707.641 177.171 716.379 168.33 727.214 168.33H732.806Z;"+this.state.Stars_int[0]+";M732.806 168.33H727.214C716.379 168.33 707.641 159.489 707.641 148.527V142.869V148.527C707.641 159.489 698.903 168.33 688.068 168.33H682.476H688.068C698.903 168.33 707.641 177.171 707.641 188.134V193.792V188.134C707.641 177.171 716.379 168.33 727.214 168.33H732.806Z"} />
                        </path>
                        <path id="Vector_2" d="M215.524 97.6033H209.932C199.097 97.6033 190.359 88.7625 190.359 77.7998V72.1416V77.7998C190.359 88.7625 181.621 97.6033 170.786 97.6033H165.194H170.786C181.621 97.6033 190.359 106.444 190.359 117.407V123.065V117.407C190.359 106.444 199.097 97.6033 209.932 97.6033H215.524Z" fill="url(#paint1_linear)">
                            <animate dur="1s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M215.524 97.6033H209.932C199.097 97.6033 190.359 88.7625 190.359 77.7998V72.1416V77.7998C190.359 88.7625 181.621 97.6033 170.786 97.6033H165.194H170.786C181.621 97.6033 190.359 106.444 190.359 117.407V123.065V117.407C190.359 106.444 199.097 97.6033 209.932 97.6033H215.524Z;"+this.state.Stars_int[1]+";M215.524 97.6033H209.932C199.097 97.6033 190.359 88.7625 190.359 77.7998V72.1416V77.7998C190.359 88.7625 181.621 97.6033 170.786 97.6033H165.194H170.786C181.621 97.6033 190.359 106.444 190.359 117.407V123.065V117.407C190.359 106.444 199.097 97.6033 209.932 97.6033H215.524Z"} />
                        </path>
                        <path id="Vector_3" d="M243.835 285.384H238.243C227.408 285.384 218.67 276.543 218.67 265.58V259.922V265.58C218.67 276.543 209.932 285.384 199.097 285.384H193.505H199.097C209.932 285.384 218.67 294.225 218.67 305.187V310.845V305.187C218.67 294.225 227.408 285.384 238.243 285.384H243.835Z" fill="url(#paint2_linear)">
                            <animate dur="1.2s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M243.835 285.384H238.243C227.408 285.384 218.67 276.543 218.67 265.58V259.922V265.58C218.67 276.543 209.932 285.384 199.097 285.384H193.505H199.097C209.932 285.384 218.67 294.225 218.67 305.187V310.845V305.187C218.67 294.225 227.408 285.384 238.243 285.384H243.835Z;"+this.state.Stars_int[2]+";M243.835 285.384H238.243C227.408 285.384 218.67 276.543 218.67 265.58V259.922V265.58C218.67 276.543 209.932 285.384 199.097 285.384H193.505H199.097C209.932 285.384 218.67 294.225 218.67 305.187V310.845V305.187C218.67 294.225 227.408 285.384 238.243 285.384H243.835Z"} />
                        </path>
                        <path id="Vector_4" d="M626.204 97.6033H621.66C613.272 97.6033 606.282 90.5306 606.282 82.0434V77.7997V82.397C606.282 90.8843 599.291 97.957 590.903 97.957H586.359H590.903C599.291 97.957 606.282 105.03 606.282 113.517V118.114V113.163C606.282 104.676 613.272 97.6033 621.66 97.6033H626.204Z" fill="url(#paint3_linear)">
                            <animate dur="1.8s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M626.204 97.6033H621.66C613.272 97.6033 606.282 90.5306 606.282 82.0434V77.7997V82.397C606.282 90.8843 599.291 97.957 590.903 97.957H586.359H590.903C599.291 97.957 606.282 105.03 606.282 113.517V118.114V113.163C606.282 104.676 613.272 97.6033 621.66 97.6033H626.204Z;"+this.state.Stars_int[3]+";M626.204 97.6033H621.66C613.272 97.6033 606.282 90.5306 606.282 82.0434V77.7997V82.397C606.282 90.8843 599.291 97.957 590.903 97.957H586.359H590.903C599.291 97.957 606.282 105.03 606.282 113.517V118.114V113.163C606.282 104.676 613.272 97.6033 621.66 97.6033H626.204Z"} />
                        </path>
                        <path id="Vector_5" d="M717.777 297.054H713.233C704.845 297.054 697.854 289.981 697.854 281.494V276.896V281.494C697.854 289.981 690.864 297.054 682.476 297.054H677.932H682.476C690.864 297.054 697.854 304.126 697.854 312.614V317.211V312.614C697.854 304.126 704.845 297.054 713.233 297.054H717.777Z" fill="url(#paint4_linear)">
                            <animate dur="1.4s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M717.777 297.054H713.233C704.845 297.054 697.854 289.981 697.854 281.494V276.896V281.494C697.854 289.981 690.864 297.054 682.476 297.054H677.932H682.476C690.864 297.054 697.854 304.126 697.854 312.614V317.211V312.614C697.854 304.126 704.845 297.054 713.233 297.054H717.777Z;"+this.state.Stars_int[4]+";M717.777 297.054H713.233C704.845 297.054 697.854 289.981 697.854 281.494V276.896V281.494C697.854 289.981 690.864 297.054 682.476 297.054H677.932H682.476C690.864 297.054 697.854 304.126 697.854 312.614V317.211V312.614C697.854 304.126 704.845 297.054 713.233 297.054H717.777Z"} />
                        </path>
                        <path id="Vector_6" d="M330.864 164.087H326.67C318.981 164.087 312.689 157.721 312.689 149.941V145.698V149.941C312.689 157.721 306.398 164.087 298.709 164.087H294.515H298.709C306.398 164.087 312.689 170.452 312.689 178.232V182.476V178.232C312.689 170.452 318.981 164.087 326.67 164.087H330.864Z" fill="url(#paint5_linear)">
                            <animate dur="1s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M330.864 164.087H326.67C318.981 164.087 312.689 157.721 312.689 149.941V145.698V149.941C312.689 157.721 306.398 164.087 298.709 164.087H294.515H298.709C306.398 164.087 312.689 170.452 312.689 178.232V182.476V178.232C312.689 170.452 318.981 164.087 326.67 164.087H330.864Z;"+this.state.Stars_int[5]+";M330.864 164.087H326.67C318.981 164.087 312.689 157.721 312.689 149.941V145.698V149.941C312.689 157.721 306.398 164.087 298.709 164.087H294.515H298.709C306.398 164.087 312.689 170.452 312.689 178.232V182.476V178.232C312.689 170.452 318.981 164.087 326.67 164.087H330.864Z"} />
                        </path>
                        <path id="Vector_7" d="M483.252 82.3971H474.864C458.786 82.3971 445.854 69.3125 445.854 53.0453V44.5581V53.0453C445.854 69.3125 432.922 82.3971 416.845 82.3971H408.456H416.845C432.922 82.3971 445.854 95.4816 445.854 111.749V120.236V111.749C445.854 95.4816 458.786 82.3971 474.864 82.3971H483.252Z" fill="url(#paint6_linear)">
                            <animate dur="0.8s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M483.252 82.3971H474.864C458.786 82.3971 445.854 69.3125 445.854 53.0453V44.5581V53.0453C445.854 69.3125 432.922 82.3971 416.845 82.3971H408.456H416.845C432.922 82.3971 445.854 95.4816 445.854 111.749V120.236V111.749C445.854 95.4816 458.786 82.3971 474.864 82.3971H483.252Z;"+this.state.Stars_int[6]+";M483.252 82.3971H474.864C458.786 82.3971 445.854 69.3125 445.854 53.0453V44.5581V53.0453C445.854 69.3125 432.922 82.3971 416.845 82.3971H408.456H416.845C432.922 82.3971 445.854 95.4816 445.854 111.749V120.236V111.749C445.854 95.4816 458.786 82.3971 474.864 82.3971H483.252Z"} />
                        </path>
                    </g>
                    <path id="Shadow" d="M838 620.339C838 684.502 675.317 712 474.5 712C273.683 712 111 684.502 111 620.339C111 556.176 273.683 504 474.5 504C674.967 504 838 556.176 838 620.339Z" fill="url(#paint7_radial)"/>
                    
                    <g id="Leaf_4">
                        <path id="Vector_8" d="M321.078 441.337L283.33 312.26C283.33 312.26 378.049 364.952 321.078 441.337Z" fill="url(#paint8_linear)">
                            <animate dur="3s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M321.078 441.337L283.33 312.26C283.33 312.26 378.049 364.952 321.078 441.337Z;"+this.state.Leaf_4_int[0]+"; M321.078 441.337L283.33 312.26C283.33 312.26 378.049 364.952 321.078 441.337Z"} />
                        </path>
                        <path id="Vector_9" d="M321.078 441.337L283.33 312.26C283.33 312.26 232.301 408.802 321.078 441.337Z" fill="url(#paint9_linear)">
                            <animate dur="3s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M321.078 441.337L283.33 312.26C283.33 312.26 232.301 408.802 321.078 441.337Z;"+this.state.Leaf_4_int[1]+";M321.078 441.337L283.33 312.26C283.33 312.26 232.301 408.802 321.078 441.337Z"} />
                        </path>
                    </g>
                    <g id="Leaf_3">
                        <path id="Vector_10" d="M362.67 532.221L158.903 348.684C158.903 348.684 380.495 336.661 362.67 532.221Z" fill="url(#paint10_linear)">
                            <animate dur="5s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M362.67 532.221L158.903 348.684C158.903 348.684 380.495 336.661 362.67 532.221Z;"+this.state.Leaf_3_int[0]+";M362.67 532.221L158.903 348.684C158.903 348.684 380.495 336.661 362.67 532.221Z"} />
                        </path>
                        <path id="Vector_11" d="M362.67 532.221L158.903 348.684C158.903 348.684 172.884 572.536 362.67 532.221Z" fill="url(#paint11_linear)">
                            <animate dur="5s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M362.67 532.221L158.903 348.684C158.903 348.684 172.884 572.536 362.67 532.221Z;"+this.state.Leaf_3_int[1]+";M362.67 532.221L158.903 348.684C158.903 348.684 172.884 572.536 362.67 532.221Z"} />
                        </path>
                    </g>
                    <g id="Leaf_2">
                        <path id="Vector_12" d="M612.922 523.734L784.184 320.747C784.184 320.747 799.913 537.172 612.922 523.734Z" fill="url(#paint12_linear)">
                            <animate dur="5s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M612.922 523.734L784.184 320.747C784.184 320.747 799.913 537.172 612.922 523.734Z;"+this.state.Leaf_2_int[0]+";M612.922 523.734L784.184 320.747C784.184 320.747 799.913 537.172 612.922 523.734Z"} />
                        </path>
                        <path id="Vector_13" d="M612.922 523.734L784.184 320.747C784.184 320.747 570.281 338.783 612.922 523.734Z" fill="url(#paint13_linear)">
                            <animate dur="5s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M612.922 523.734L784.184 320.747C784.184 320.747 570.281 338.783 612.922 523.734Z;"+this.state.Leaf_2_int[1]+";M612.922 523.734L784.184 320.747C784.184 320.747 570.281 338.783 612.922 523.734Z"} />
                        </path>
                    </g>
                    <g id="Leaf_1">
                        <path id="Vector_14" d="M622.359 512.418L795.369 472.103C795.369 472.103 718.126 595.522 622.359 512.418Z" fill="url(#paint14_linear)">
                            <animate dur="3s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M622.359 512.418L795.369 472.103C795.369 472.103 718.126 595.522 622.359 512.418Z;"+this.state.Leaf_1_int[0]+";M622.359 512.418L795.369 472.103C795.369 472.103 718.126 595.522 622.359 512.418Z"} />
                        </path>
                        <path id="Vector_15" d="M622.359 512.418L795.369 472.103C795.369 472.103 672.689 395.364 622.359 512.418Z" fill="url(#paint15_linear)">
                            <animate dur="3s" repeatCount="indefinite" attributeName="d" 
                            calcMode="spline"
                            keySplines="0.33 0 0.33 1; 0.33 0 0.33 1"
                            values={"M622.359 512.418L795.369 472.103C795.369 472.103 672.689 395.364 622.359 512.418Z;"+this.state.Leaf_1_int[1]+";M622.359 512.418L795.369 472.103C795.369 472.103 672.689 395.364 622.359 512.418Z"} />
                        </path>
                    </g>
                    <g id="Coins_2">
                        <rect id="Rectangle" x="178.476" y="560.512" width="164.272" height="99.0179" fill="url(#pattern0)"/>
                        <path id="Vector_16" d="M261.311 621.337C299.917 621.337 331.214 609.463 331.214 594.815C331.214 580.167 299.917 568.292 261.311 568.292C222.704 568.292 191.408 580.167 191.408 594.815C191.408 609.463 222.704 621.337 261.311 621.337Z" fill="url(#paint16_linear)"/>
                        <path id="Vector_17" d="M261.311 610.375C290.651 610.375 314.437 602.933 314.437 593.754C314.437 584.574 290.651 577.133 261.311 577.133C231.97 577.133 208.184 584.574 208.184 593.754C208.184 602.933 231.97 610.375 261.311 610.375Z" fill="url(#paint17_linear)"/>
                        <path id="Vector_18" d="M261.311 580.669C288.922 580.669 311.291 587.035 314.087 595.522C314.437 594.815 314.437 594.461 314.437 593.754C314.437 584.559 290.67 577.133 261.311 577.133C231.951 577.133 208.184 584.559 208.184 593.754C208.184 594.461 208.184 594.815 208.534 595.522C211.33 587.035 234.049 580.669 261.311 580.669Z" fill="url(#paint18_linear)"/>
                        <path id="Vector_19" d="M191.408 617.094C191.408 631.593 222.864 643.616 261.311 643.616C299.757 643.616 331.214 631.593 331.214 617.094V594.815C331.214 609.314 299.757 621.337 261.311 621.337C222.864 621.337 191.408 609.314 191.408 594.815V617.094Z" fill="url(#paint19_linear)"/>
                        <path id="Vector_20" d="M236.146 619.569C209.932 615.679 191.408 606.131 191.408 594.815V617.094C191.408 628.41 209.932 637.958 236.146 641.848V619.569Z" fill="url(#paint20_linear)"/>
                        <path id="Vector_21" d="M261.311 621.337H252.922V643.616H261.311V621.337Z" fill="url(#paint21_linear)"/>
                        <path id="Vector_22" d="M291.719 597.29V596.229C291.369 593.754 288.573 591.985 285.078 590.571V587.035V586.327C284.379 582.084 275.641 579.962 267.602 579.962H264.456V578.901C264.456 578.194 263.757 577.486 263.058 577.486H255.369C254.67 577.486 253.971 578.194 253.971 578.901V579.962H251.874C243.136 579.962 233.699 582.437 233 586.681V587.388V591.278C233 591.985 233 593.046 234.049 594.107C234.398 594.815 235.097 595.168 236.146 595.522H233H232.651H232.301V595.875V596.229V596.583V597.29V601.18C232.301 601.887 232.301 602.948 233.35 604.009C235.447 606.485 241.388 608.253 248.379 608.253H254.32V610.728C254.32 611.435 255.019 612.143 255.719 612.143H264.456C265.155 612.143 265.854 611.435 265.854 610.728V608.253H274.243C282.631 608.253 292.418 606.131 293.466 601.18V600.826C291.719 601.534 291.719 600.473 291.719 597.29V597.29ZM247.33 600.119H254.67L255.019 593.046H251.175C241.388 593.046 233.699 590.217 234.398 587.035C235.097 583.852 242.786 581.376 251.874 581.376H255.369V578.901H262.709V581.376H267.252C275.641 581.376 282.981 583.852 283.68 586.681H275.99C275.99 585.974 275.291 585.62 274.592 585.266C273.194 584.559 270.398 583.852 267.602 583.852H263.058V590.217H272.146C281.932 590.217 290.321 593.046 290.67 596.583C291.369 600.119 283.33 603.302 272.845 603.302H263.058V607.192H254.32V603.302H246.981C238.592 603.302 231.951 600.826 232.301 597.644H240.34C240.34 598.705 243.485 600.119 247.33 600.119V600.119Z" fill="url(#paint22_linear)"/>
                        <path id="Vector_23" d="M232.301 597.29C231.951 600.473 238.243 602.948 246.981 602.948H254.32V606.838H263.058V602.948H272.845C283.33 602.948 291.369 599.766 290.67 596.229C289.971 592.693 281.583 589.864 272.146 589.864H263.058V583.498H267.602C270.398 583.498 273.194 583.852 274.592 584.913C275.641 585.266 275.99 585.974 275.99 586.327H283.68C283.33 583.498 275.99 581.023 267.252 581.023H262.709V578.547H255.369V581.023H251.874C242.786 581.023 234.748 583.498 234.398 586.681C233.699 589.864 241.388 592.693 251.175 592.693H255.019L254.67 599.766H247.33C243.485 599.766 240.34 598.705 240.689 597.29H232.301V597.29ZM277.388 593.4C280.185 594.107 281.932 595.168 282.282 596.229C282.631 598.351 278.087 599.766 272.495 599.766H263.058V592.693H272.146C273.893 592.693 275.641 593.046 277.388 593.4ZM241.738 587.035C241.738 586.327 243.136 585.266 244.884 584.913C245.932 584.559 246.631 584.559 248.029 584.206C249.078 584.206 250.476 583.852 251.524 583.852H255.019L254.67 590.217H250.825C245.583 590.217 241.388 588.803 241.738 587.035V587.035Z" fill="#FCD224"/>
                        <path id="Vector_24" d="M255.019 590.217L255.369 583.852H251.874C250.476 583.852 249.427 583.852 248.379 584.206C247.33 584.206 246.281 584.559 245.233 584.913C243.485 585.62 242.437 586.327 242.087 587.035C241.738 588.803 245.932 590.217 251.524 590.217H255.019V590.217Z" fill="url(#paint23_linear)"/>
                        <path id="Vector_25" d="M262.709 600.119H272.146C277.738 600.119 282.282 598.351 281.932 596.583C281.932 595.522 279.835 594.461 277.039 593.754C275.291 593.4 273.544 593.046 271.447 593.046H262.359L262.709 600.119Z" fill="url(#paint24_linear)"/>
                        <g id="Group" opacity="0.1">
                            <path id="Vector_26" opacity="0.1" d="M268.301 596.936C306.907 596.936 338.204 585.062 338.204 570.414C338.204 555.766 306.907 543.891 268.301 543.891C229.695 543.891 198.398 555.766 198.398 570.414C198.398 585.062 229.695 596.936 268.301 596.936Z" fill="black"/>
                            <path id="Vector_27" opacity="0.1" d="M268.301 585.974C297.642 585.974 321.427 578.532 321.427 569.353C321.427 560.173 297.642 552.732 268.301 552.732C238.96 552.732 215.175 560.173 215.175 569.353C215.175 578.532 238.96 585.974 268.301 585.974Z" fill="black"/>
                            <path id="Vector_28" opacity="0.1" d="M268.301 556.622C295.913 556.622 318.282 562.987 321.078 571.475C321.427 570.767 321.427 570.414 321.427 569.706C321.427 560.512 297.66 553.086 268.301 553.086C238.942 553.086 215.175 560.512 215.175 569.706C215.175 570.414 215.175 570.767 215.524 571.475C218.32 562.987 241.039 556.622 268.301 556.622Z" fill="black"/>
                            <path id="Vector_29" opacity="0.1" d="M198.398 592.693C198.398 607.192 229.854 619.215 268.301 619.215C306.748 619.215 338.204 607.192 338.204 592.693V570.414C338.204 584.913 306.748 596.936 268.301 596.936C229.854 596.936 198.398 584.913 198.398 570.414V592.693Z" fill="black"/>
                            <path id="Vector_30" opacity="0.1" d="M243.136 595.168C216.922 591.278 198.398 581.73 198.398 570.414V592.693C198.398 604.009 216.922 613.557 243.136 617.447V595.168Z" fill="black"/>
                            <path id="Vector_31" opacity="0.1" d="M268.301 597.29H259.913V619.569H268.301V597.29Z" fill="black"/>
                            <path id="Vector_32" opacity="0.1" d="M298.709 572.889V571.828C298.359 569.353 295.563 567.585 292.068 566.17V562.634V561.926C291.369 557.683 282.631 555.561 274.592 555.561H271.447V554.5C271.447 553.793 270.748 553.086 270.049 553.086H262.359C261.66 553.086 260.961 553.793 260.961 554.5V555.561H258.864C250.126 555.561 240.689 558.036 239.99 562.28V562.987V566.877C239.99 567.585 239.99 568.645 241.039 569.706C241.388 570.414 242.087 570.767 243.136 571.121H239.99H239.641H239.291V571.475V571.828V572.182V572.889V576.779C239.291 577.486 239.291 578.547 240.34 579.608C242.437 582.084 248.379 583.852 255.369 583.852H261.311V586.327C261.311 587.035 262.01 587.742 262.709 587.742H271.447C272.146 587.742 272.845 587.035 272.845 586.327V583.852H281.233C289.621 583.852 299.408 581.73 300.456 576.779V576.425C298.709 577.486 298.709 576.425 298.709 572.889V572.889ZM254.32 575.718H261.66L262.01 568.645H258.165C248.379 568.645 240.689 565.816 241.388 562.634C242.087 559.451 249.777 556.976 258.864 556.976H262.359V554.5H269.699V556.976H274.243C282.631 556.976 289.971 559.451 290.67 562.28H282.981C282.981 561.573 282.282 561.219 281.583 560.866C280.184 560.158 277.388 559.451 274.592 559.451H270.049V565.816H279.136C288.922 565.816 297.311 568.645 297.66 572.182C298.359 575.718 290.32 578.901 279.835 578.901H270.049V582.791H261.311V578.901H253.971C245.583 578.901 238.942 576.425 239.291 573.243H247.33C247.33 574.657 250.476 575.718 254.32 575.718V575.718Z" fill="black"/>
                            <path id="Vector_33" opacity="0.1" d="M239.291 573.243C238.942 576.426 245.233 578.901 253.971 578.901H261.311V582.791H270.049V578.901H279.835C290.32 578.901 298.359 575.718 297.66 572.182C296.961 568.646 288.573 565.816 279.136 565.816H270.049V559.451H274.592C277.388 559.451 280.185 559.805 281.583 560.866C282.631 561.219 282.981 561.926 282.981 562.28H290.67C290.32 559.451 282.981 556.976 274.243 556.976H269.699V554.5H262.359V556.976H258.864C249.777 556.976 241.738 559.451 241.388 562.634C240.689 565.817 248.379 568.646 258.165 568.646H262.01L261.66 575.718H254.32C250.476 575.718 247.33 574.657 247.68 573.243H239.291V573.243ZM284.379 569.353C287.175 570.06 288.922 571.121 289.272 572.182C289.621 574.304 285.078 575.718 279.485 575.718H270.049V568.646H279.136C280.884 568.646 282.631 568.999 284.379 569.353ZM248.728 562.634C248.728 561.927 250.126 560.866 251.874 560.512C252.922 560.158 253.621 560.158 255.019 559.805C256.068 559.805 257.466 559.451 258.515 559.451H262.01L261.66 565.816H257.816C252.573 566.17 248.379 564.402 248.728 562.634V562.634Z" fill="black"/>
                            <path id="Vector_34" opacity="0.1" d="M262.01 566.17L262.359 559.805H258.864C257.466 559.805 256.417 559.805 255.369 560.158C254.32 560.158 253.272 560.512 252.223 560.866C250.476 561.573 249.427 562.28 249.078 562.987C248.728 564.756 252.922 566.17 258.515 566.17H262.01V566.17Z" fill="black"/>
                            <path id="Vector_35" opacity="0.1" d="M269.699 575.718H279.136C284.728 575.718 289.272 573.95 288.922 572.182C288.922 571.121 286.825 570.06 284.029 569.353C282.282 568.999 280.534 568.646 278.437 568.646H269.349L269.699 575.718Z" fill="black"/>
                        </g>
                        <path id="Vector_36" d="M268.301 593.4C306.907 593.4 338.204 581.525 338.204 566.877C338.204 552.229 306.907 540.355 268.301 540.355C229.695 540.355 198.398 552.229 198.398 566.877C198.398 581.525 229.695 593.4 268.301 593.4Z" fill="url(#paint25_linear)"/>
                        <path id="Vector_37" d="M268.301 582.437C297.642 582.437 321.427 574.996 321.427 565.816C321.427 556.637 297.642 549.196 268.301 549.196C238.96 549.196 215.175 556.637 215.175 565.816C215.175 574.996 238.96 582.437 268.301 582.437Z" fill="url(#paint26_linear)"/>
                        <path id="Vector_38" d="M268.301 553.086C295.913 553.086 318.282 559.451 321.078 567.938C321.427 567.231 321.427 566.877 321.427 566.17C321.427 556.976 297.66 549.549 268.301 549.549C238.942 549.549 215.175 556.976 215.175 566.17C215.175 566.877 215.175 567.231 215.524 567.938C218.32 559.451 241.039 553.086 268.301 553.086Z" fill="url(#paint27_linear)"/>
                        <path id="Vector_39" d="M198.398 589.156C198.398 603.655 229.854 615.679 268.301 615.679C306.748 615.679 338.204 603.655 338.204 589.156V566.877C338.204 581.376 306.748 593.4 268.301 593.4C229.854 593.4 198.398 581.376 198.398 566.877V589.156Z" fill="url(#paint28_linear)"/>
                        <path id="Vector_40" d="M243.136 591.632C216.922 587.742 198.398 578.194 198.398 566.877V589.156C198.398 600.473 216.922 610.021 243.136 613.911V591.632Z" fill="url(#paint29_linear)"/>
                        <path id="Vector_41" d="M268.301 593.754H259.913V616.033H268.301V593.754Z" fill="url(#paint30_linear)"/>
                        <path id="Vector_42" d="M298.709 569.353V568.292C298.359 565.817 295.563 564.048 292.068 562.634V559.097V558.39C291.369 554.147 282.631 552.025 274.592 552.025H271.447V550.964C271.447 550.257 270.748 549.549 270.049 549.549H262.359C261.66 549.549 260.961 550.257 260.961 550.964V552.025H258.864C250.126 552.025 240.689 554.5 239.99 558.744V559.451V563.341C239.99 564.048 239.99 565.109 241.039 566.17C241.388 566.877 242.087 567.231 243.136 567.585H239.99H239.641H239.291V567.938V568.292V568.646V569.353V573.243C239.291 573.95 239.291 575.011 240.34 576.072C242.437 578.547 248.379 580.316 255.369 580.316H261.311V582.791C261.311 583.498 262.01 584.206 262.709 584.206H271.447C272.146 584.206 272.845 583.498 272.845 582.791V580.316H281.233C289.621 580.316 299.408 578.194 300.456 573.243V572.889C298.709 573.95 298.709 572.889 298.709 569.353V569.353ZM254.32 572.182H261.66L262.01 565.109H258.165C248.379 565.109 240.689 562.28 241.388 559.097C242.087 555.915 249.777 553.439 258.864 553.439H262.359V550.964H269.699V553.439H274.243C282.631 553.439 289.971 555.915 290.67 558.744H282.981C282.981 558.037 282.282 557.683 281.583 557.329C280.184 556.622 277.388 555.915 274.592 555.915H270.049V562.28H279.136C288.922 562.28 297.311 565.109 297.66 568.646C298.359 572.182 290.32 575.365 279.835 575.365H270.049V579.255H261.311V575.365H253.971C245.583 575.365 238.942 572.889 239.291 569.707H247.33C247.33 571.121 250.476 572.182 254.32 572.182V572.182Z" fill="url(#paint31_linear)"/>
                        <path id="Vector_43" d="M239.291 569.706C238.942 572.889 245.233 575.365 253.971 575.365H261.311V579.255H270.049V575.365H279.835C290.32 575.365 298.359 572.182 297.66 568.646C296.961 565.109 288.573 562.28 279.136 562.28H270.049V555.915H274.592C277.388 555.915 280.184 556.268 281.582 557.329C282.631 557.683 282.981 558.39 282.981 558.744H290.67C290.32 555.915 282.981 553.439 274.243 553.439H269.699V550.964H262.359V553.439H258.864C249.777 553.439 241.738 555.915 241.388 559.097C240.689 562.28 248.379 565.109 258.165 565.109H262.01L261.66 572.182H254.32C250.476 572.182 247.33 571.121 247.68 569.706H239.291V569.706ZM284.379 565.816C287.175 566.524 288.922 567.585 289.272 568.646C289.621 570.767 285.078 572.182 279.485 572.182H270.049V565.109H279.136C280.883 565.109 282.631 565.463 284.379 565.816ZM248.728 559.097C248.728 558.39 250.126 557.329 251.874 556.976C252.922 556.622 253.621 556.622 255.019 556.268C256.068 556.268 257.466 555.915 258.515 555.915H262.01L261.66 562.28H257.816C252.573 562.634 248.379 560.866 248.728 559.097V559.097Z" fill="#FCD224"/>
                        <path id="Vector_44" d="M262.01 562.634L262.359 556.268H258.864C257.466 556.268 256.417 556.268 255.369 556.622C254.32 556.622 253.272 556.976 252.223 557.329C250.476 558.036 249.427 558.744 249.078 559.451C248.728 561.219 252.922 562.634 258.515 562.634H262.01V562.634Z" fill="url(#paint32_linear)"/>
                        <path id="Vector_45" d="M269.699 572.182H279.136C284.728 572.182 289.272 570.414 288.922 568.646C288.922 567.585 286.825 566.524 284.029 565.816C282.282 565.463 280.534 565.109 278.437 565.109H269.349L269.699 572.182Z" fill="url(#paint33_linear)"/>
                        <g id="Group_2" opacity="0.1">
                            <path id="Vector_46" opacity="0.1" d="M243.835 567.585C282.441 567.585 313.738 555.71 313.738 541.062C313.738 526.414 282.441 514.539 243.835 514.539C205.229 514.539 173.932 526.414 173.932 541.062C173.932 555.71 205.229 567.585 243.835 567.585Z" fill="black"/>
                            <path id="Vector_47" opacity="0.1" d="M243.835 556.622C273.176 556.622 296.961 549.181 296.961 540.001C296.961 530.822 273.176 523.38 243.835 523.38C214.494 523.38 190.709 530.822 190.709 540.001C190.709 549.181 214.494 556.622 243.835 556.622Z" fill="black"/>
                            <path id="Vector_48" opacity="0.1" d="M243.835 526.917C271.447 526.917 293.816 533.282 296.612 541.769C296.961 541.062 296.961 540.708 296.961 540.001C296.961 530.807 273.194 523.38 243.835 523.38C214.476 523.38 190.709 530.807 190.709 540.001C190.709 540.708 190.709 541.062 191.058 541.769C193.854 533.282 216.573 526.917 243.835 526.917Z" fill="black"/>
                            <path id="Vector_49" opacity="0.1" d="M173.932 563.341C173.932 577.84 205.388 589.864 243.835 589.864C282.282 589.864 313.738 577.84 313.738 563.341V541.062C313.738 555.561 282.282 567.585 243.835 567.585C205.388 567.585 173.932 555.561 173.932 541.062V563.341Z" fill="black"/>
                            <path id="Vector_50" opacity="0.1" d="M218.67 565.816C192.456 561.926 173.932 552.378 173.932 541.062V563.341C173.932 574.657 192.456 584.206 218.67 588.095V565.816Z" fill="black"/>
                            <path id="Vector_51" opacity="0.1" d="M243.835 567.585H235.447V589.864H243.835V567.585Z" fill="black"/>
                            <path id="Vector_52" opacity="0.1" d="M274.243 543.537V542.477C273.893 540.001 271.097 538.233 267.602 536.818V533.282V532.575C266.903 528.331 258.165 526.209 250.126 526.209H246.981V525.148C246.981 524.441 246.282 523.734 245.583 523.734H237.893C237.194 523.734 236.495 524.441 236.495 525.148V526.209H234.398C225.66 526.209 216.223 528.685 215.524 532.928V533.636V537.526C215.524 538.233 215.524 539.294 216.573 540.355C216.922 541.062 217.621 541.416 218.67 541.769H215.524H215.175H214.825V542.123V542.477V542.83V543.537V547.428C214.825 548.135 214.825 549.196 215.874 550.257C217.971 552.732 223.913 554.5 230.903 554.5H236.845V556.976C236.845 557.683 237.544 558.39 238.243 558.39H246.981C247.68 558.39 248.379 557.683 248.379 556.976V554.5H256.767C265.155 554.5 274.942 552.378 275.99 547.428V547.074C274.243 547.781 274.243 547.074 274.243 543.537V543.537ZM229.854 546.367H237.194L237.544 539.294H233.699C223.913 539.294 216.223 536.465 216.922 533.282C217.621 530.099 225.311 527.624 234.398 527.624H237.893V525.148H245.233V527.624H249.777C258.165 527.624 265.505 530.099 266.204 532.928H258.515C258.515 532.221 257.816 531.868 257.117 531.514C255.719 530.807 252.922 530.099 250.126 530.099H245.583V536.465H254.67C264.456 536.465 272.845 539.294 273.194 542.83C273.893 546.367 265.854 549.549 255.369 549.549H245.583V553.439H236.845V549.549H229.505C221.117 549.549 214.476 547.074 214.825 543.891H222.864C222.864 544.952 226.01 546.367 229.854 546.367V546.367Z" fill="black"/>
                            <path id="Vector_53" opacity="0.1" d="M214.825 543.891C214.476 547.074 220.767 549.549 229.505 549.549H236.845V553.439H245.583V549.549H255.369C265.855 549.549 273.893 546.367 273.194 542.83C272.495 539.294 264.107 536.465 254.67 536.465H245.583V530.099H250.126C252.922 530.099 255.719 530.453 257.117 531.514C258.165 531.868 258.515 532.575 258.515 532.928H266.204C265.855 530.099 258.515 527.624 249.777 527.624H245.233V525.148H237.893V527.624H234.398C225.311 527.624 217.272 530.099 216.922 533.282C216.223 536.465 223.913 539.294 233.699 539.294H237.544L237.194 546.367H229.854C226.01 546.367 222.864 545.306 223.214 543.891H214.825V543.891ZM259.913 539.648C262.709 540.355 264.456 541.416 264.806 542.477C265.155 544.598 260.612 546.013 255.02 546.013H245.583V538.94H254.67C256.418 539.294 258.165 539.294 259.913 539.648ZM224.262 533.282C224.262 532.575 225.66 531.514 227.408 531.16C228.456 530.807 229.155 530.807 230.553 530.453C231.602 530.453 233 530.099 234.049 530.099H237.544L237.194 536.465H233.35C228.107 536.465 223.913 535.05 224.262 533.282V533.282Z" fill="black"/>
                            <path id="Vector_54" opacity="0.1" d="M237.544 536.465L237.893 530.099H234.398C233 530.099 231.951 530.099 230.903 530.453C229.854 530.453 228.806 530.807 227.757 531.16C226.01 531.867 224.961 532.575 224.612 533.282C224.262 535.05 228.456 536.465 234.049 536.465H237.544V536.465Z" fill="black"/>
                            <path id="Vector_55" opacity="0.1" d="M245.233 546.367H254.67C260.262 546.367 264.806 544.598 264.456 542.83C264.456 541.769 262.359 540.708 259.563 540.001C257.816 539.647 256.068 539.294 253.971 539.294H244.884L245.233 546.367Z" fill="black"/>
                        </g>
                        <path id="Vector_56" d="M243.835 564.048C282.441 564.048 313.738 552.174 313.738 537.526C313.738 522.878 282.441 511.003 243.835 511.003C205.229 511.003 173.932 522.878 173.932 537.526C173.932 552.174 205.229 564.048 243.835 564.048Z" fill="url(#paint34_linear)"/>
                        <path id="Vector_57" d="M243.835 553.086C273.176 553.086 296.961 545.644 296.961 536.465C296.961 527.285 273.176 519.844 243.835 519.844C214.494 519.844 190.709 527.285 190.709 536.465C190.709 545.644 214.494 553.086 243.835 553.086Z" fill="url(#paint35_linear)"/>
                        <path id="Vector_58" d="M243.835 523.38C271.447 523.38 293.816 529.746 296.612 538.233C296.961 537.526 296.961 537.172 296.961 536.465C296.961 527.27 273.194 519.844 243.835 519.844C214.476 519.844 190.709 527.27 190.709 536.465C190.709 537.172 190.709 537.526 191.058 538.233C193.854 529.746 216.573 523.38 243.835 523.38Z" fill="url(#paint36_linear)"/>
                        <path id="Vector_59" d="M173.932 559.805C173.932 574.304 205.388 586.327 243.835 586.327C282.282 586.327 313.738 574.304 313.738 559.805V537.526C313.738 552.025 282.282 564.048 243.835 564.048C205.388 564.048 173.932 552.025 173.932 537.526V559.805Z" fill="url(#paint37_linear)"/>
                        <path id="Vector_60" d="M218.67 562.28C192.456 558.39 173.932 548.842 173.932 537.526V559.805C173.932 571.121 192.456 580.669 218.67 584.559V562.28Z" fill="url(#paint38_linear)"/>
                        <path id="Vector_61" d="M243.835 564.048H235.447V586.327H243.835V564.048Z" fill="url(#paint39_linear)"/>
                        <path id="Vector_62" d="M274.243 540.001V538.94C273.893 536.465 271.097 534.697 267.602 533.282V529.746V529.038C266.903 524.795 258.165 522.673 250.126 522.673H246.981V521.612C246.981 520.905 246.282 520.198 245.583 520.198H237.893C237.194 520.198 236.495 520.905 236.495 521.612V522.673H234.398C225.66 522.673 216.223 525.148 215.524 529.392V530.099V533.989C215.524 534.697 215.524 535.758 216.573 536.818C216.922 537.526 217.621 537.879 218.67 538.233H215.524H215.175H214.825V538.587V538.94V539.294V540.001V543.891C214.825 544.598 214.825 545.659 215.874 546.72C217.971 549.196 223.913 550.964 230.903 550.964H236.845V553.439C236.845 554.147 237.544 554.854 238.243 554.854H246.981C247.68 554.854 248.379 554.147 248.379 553.439V550.964H256.767C265.155 550.964 274.942 548.842 275.99 543.891V543.537C274.243 544.245 274.243 543.537 274.243 540.001V540.001ZM229.854 542.83H237.194L237.544 535.758H233.699C223.913 535.758 216.223 532.928 216.922 529.746C217.621 526.563 225.311 524.088 234.398 524.088H237.893V521.612H245.233V524.088H249.777C258.165 524.088 265.505 526.563 266.204 529.392H258.515C258.515 528.685 257.816 528.331 257.117 527.978C255.719 527.27 252.922 526.563 250.126 526.563H245.583V532.928H254.67C264.456 532.928 272.845 535.758 273.194 539.294C273.893 542.83 265.854 546.013 255.369 546.013H245.583V549.903H236.845V546.013H229.505C221.117 546.013 214.476 543.537 214.825 540.355H222.864C222.864 541.416 226.01 542.83 229.854 542.83V542.83Z" fill="url(#paint40_linear)"/>
                        <path id="Vector_63" d="M214.825 540.355C214.476 543.537 220.767 546.013 229.505 546.013H236.845V549.903H245.583V546.013H255.369C265.854 546.013 273.893 542.83 273.194 539.294C272.495 535.758 264.107 532.928 254.67 532.928H245.583V526.563H250.126C252.922 526.563 255.718 526.917 257.117 527.978C258.165 528.331 258.515 529.038 258.515 529.392H266.204C265.854 526.563 258.515 524.088 249.777 524.088H245.233V521.612H237.893V524.088H234.398C225.311 524.088 217.272 526.563 216.922 529.746C216.223 532.928 223.913 535.758 233.699 535.758H237.544L237.194 542.83H229.854C226.01 542.83 222.864 541.769 223.214 540.355H214.825V540.355ZM259.913 536.111C262.709 536.818 264.456 537.879 264.806 538.94C265.155 541.062 260.612 542.477 255.019 542.477H245.583V535.404H254.67C256.418 535.757 258.165 535.758 259.913 536.111ZM224.262 529.746C224.262 529.038 225.66 527.978 227.408 527.624C228.456 527.27 229.155 527.27 230.553 526.917C231.602 526.917 233 526.563 234.049 526.563H237.544L237.194 532.928H233.35C228.107 532.928 223.913 531.514 224.262 529.746V529.746Z" fill="#FCD224"/>
                        <path id="Vector_64" d="M237.544 532.928L237.893 526.563H234.398C233 526.563 231.951 526.563 230.903 526.917C229.854 526.917 228.806 527.27 227.757 527.624C226.01 528.331 224.961 529.038 224.612 529.746C224.262 531.514 228.456 532.928 234.048 532.928H237.544V532.928Z" fill="url(#paint41_linear)"/>
                        <path id="Vector_65" d="M245.233 542.83H254.67C260.262 542.83 264.806 541.062 264.456 539.294C264.456 538.233 262.359 537.172 259.563 536.465C257.816 536.111 256.068 535.758 253.971 535.758H244.884L245.233 542.83Z" fill="url(#paint42_linear)"/>
                    </g>
                    <g id="Wallet_back">
                        <path id="Vector_66" d="M331.563 378.39C409.505 376.622 623.757 363.891 641.583 388.645C623.408 413.046 401.816 401.376 317.932 399.254V392.535C318.282 384.402 324.223 378.39 331.563 378.39Z" fill="url(#paint43_linear)"/>
                        <path id="Vector_67" d="M331.563 369.903C413.35 368.134 643.33 353.989 643.33 384.402C643.33 385.816 642.981 387.231 641.932 388.645C624.107 363.891 409.505 376.975 331.913 378.39C324.573 378.39 318.631 384.755 318.631 392.182V383.694C318.282 376.268 324.223 369.903 331.563 369.903V369.903Z" fill="url(#paint44_linear)"/>
                    </g>
                    <g id="Note_3">
                        <path id="Vector_68" d="M576.573 229.863L565.039 618.508L355.68 612.143L366.864 223.498L576.573 229.863Z" fill="url(#paint45_linear)"/>
                        <path id="Vector_69" d="M384.033 241.534L373.659 595.028L548.346 600.276L558.72 246.783L384.033 241.534Z" stroke="#8BD6B5" stroke-miterlimit="10"/>
                        <path id="Vector_70" d="M390.813 248.814L380.854 588.168L541.566 592.997L551.525 253.643L390.813 248.814Z" stroke="#8BD6B5" stroke-width="0.5" stroke-miterlimit="10"/>
                        <path id="Vector_71" d="M423.485 443.105L418.243 416.229L418.592 407.034L495.136 409.156L495.485 398.901L515.058 399.608L513.66 441.69L494.087 440.983L494.437 429.313L437.466 427.545L437.117 442.751L423.485 443.105V443.105Z" fill="#A2DEC4"/>
                        <path id="Vector_72" d="M427.68 540.001C426.981 559.451 442.359 575.718 461.582 576.426C480.806 577.133 496.883 561.573 497.582 542.123C498.281 522.673 482.903 506.406 463.68 505.698C444.107 505.345 428.029 520.551 427.68 540.001ZM457.738 545.306C458.437 544.598 459.136 543.537 459.835 541.769C461.233 538.587 462.631 536.111 464.029 534.697C465.427 533.282 467.524 532.575 470.32 532.575C472.767 532.575 474.864 533.636 476.262 535.05C477.66 536.818 478.709 538.94 478.709 541.769H482.903V545.306H478.709C478.359 548.135 477.311 550.257 475.563 552.025C473.815 553.793 471.369 554.5 467.874 554.5L468.223 548.135C470.32 548.135 471.718 547.781 472.417 547.074C473.116 546.367 473.815 544.952 473.815 543.537C473.815 542.123 473.466 541.062 472.767 540.355C472.068 539.648 471.019 539.294 469.971 539.294C468.922 539.294 467.874 539.648 467.175 540.355C466.476 541.062 465.777 542.123 465.078 543.891C463.68 547.074 462.281 549.549 460.883 551.317C459.485 552.732 457.388 553.439 454.592 553.439C452.146 553.439 450.048 552.378 448.65 550.964C447.252 549.196 446.204 547.074 446.204 544.245H441.311V540.708H446.204C446.553 537.879 447.951 536.111 449.699 534.697C451.447 533.282 453.893 532.575 456.689 532.575L456.34 538.94C454.592 538.94 453.194 539.294 452.146 540.001C451.097 540.708 450.748 541.769 450.748 542.83C450.748 544.245 451.097 544.952 451.796 545.659C452.495 546.367 453.544 546.72 454.592 546.72C456.34 546.013 457.039 546.013 457.738 545.306V545.306Z" fill="#A2DEC4"/>
                        <path id="Vector_73" d="M434.67 300.59C433.971 320.04 449.35 336.307 468.573 337.014C487.796 337.722 503.874 322.162 504.573 302.712C505.272 283.262 489.893 266.995 470.67 266.287C451.097 265.934 435.019 281.14 434.67 300.59ZM464.728 305.894C465.427 305.187 466.126 304.126 466.825 302.358C468.223 299.175 469.621 296.7 471.019 295.285C472.418 293.871 474.515 293.164 477.311 293.164C479.757 293.164 481.854 294.225 483.252 295.639C484.651 297.407 485.699 299.529 485.699 302.358H489.893V305.894H485.699C485.35 308.724 484.301 310.845 482.553 312.614C480.806 314.382 478.359 315.089 474.864 315.089L475.214 308.724C477.311 308.724 478.709 308.37 479.408 307.663C480.107 306.955 480.806 305.541 480.806 304.126C480.806 302.712 480.456 301.651 479.757 300.944C479.058 300.236 478.01 299.883 476.961 299.883C475.913 299.883 474.864 300.236 474.165 300.944C473.466 301.651 472.767 302.712 472.068 304.48C470.67 307.663 469.272 310.138 467.874 311.906C466.476 313.321 464.379 314.028 461.583 314.028C459.136 314.028 457.039 312.967 455.641 311.553C454.243 309.784 453.194 307.663 453.194 304.834H448.301V301.297H453.194C453.544 298.468 454.942 296.7 456.689 295.285C458.437 293.871 460.884 293.164 463.68 293.164L463.33 299.529C461.583 299.529 460.184 299.883 459.136 300.59C458.087 301.297 457.738 302.358 457.738 303.419C457.738 304.834 458.087 305.541 458.786 306.248C459.486 306.955 460.534 307.309 461.583 307.309C463.33 306.602 464.029 306.602 464.728 305.894V305.894Z" fill="#A2DEC4"/>
                        <path id="Vector_74" d="M398.36 588.607C398.647 578.846 391.058 570.698 381.41 570.408C371.762 570.118 363.709 577.796 363.423 587.558C363.136 597.319 370.725 605.467 380.373 605.757C390.021 606.047 398.074 598.369 398.36 588.607Z" fill="#A2DEC4"/>
                        <path id="Vector_75" d="M558.831 593.724C559.117 583.962 551.529 575.814 541.881 575.524C532.233 575.234 524.18 582.913 523.894 592.674C523.607 602.436 531.196 610.584 540.844 610.874C550.491 611.163 558.545 603.485 558.831 593.724Z" fill="#A2DEC4"/>
                        <path id="Vector_76" d="M408.155 249.442C408.442 239.681 400.853 231.532 391.205 231.243C381.558 230.953 373.504 238.631 373.218 248.393C372.931 258.154 380.52 266.302 390.168 266.592C399.816 266.882 407.869 259.204 408.155 249.442Z" fill="#A2DEC4"/>
                        <path id="Vector_77" d="M568.984 254.216C569.27 244.455 561.681 236.306 552.034 236.017C542.386 235.727 534.333 243.405 534.046 253.166C533.76 262.928 541.348 271.076 550.996 271.366C560.644 271.656 568.697 263.978 568.984 254.216Z" fill="#A2DEC4"/>
                        <path id="Vector_78" d="M530.475 422.837C531.53 386.915 503.603 356.93 468.099 355.863C432.596 354.796 402.96 383.052 401.906 418.974C400.851 454.897 428.778 484.882 464.282 485.949C499.785 487.015 529.421 458.76 530.475 422.837Z" stroke="#8BD6B5" stroke-miterlimit="10"/>
                        <path id="Vector_79" opacity="0.5" d="M576.573 229.863L565.039 618.508L355.68 612.143L366.864 223.498L576.573 229.863Z" fill="url(#paint46_linear)"/>
                    </g>
                    <g id="Note_2">
                        <path id="Vector_80" d="M604.884 212.181L592.651 601.18L383.291 594.461L395.175 205.462L604.884 212.181Z" fill="url(#paint47_linear)"/>
                        <path id="Vector_81" d="M412.261 223.776L401.344 577.235L576.014 582.758L586.931 229.299L412.261 223.776Z" stroke="#8BD6B5" stroke-miterlimit="10"/>
                        <path id="Vector_82" d="M419.03 231.066L408.549 570.386L569.245 575.468L579.726 236.147L419.03 231.066Z" stroke="#8BD6B5" stroke-width="0.5" stroke-miterlimit="10"/>
                        <path id="Vector_83" d="M451.447 425.423L446.204 398.547L446.553 389.352L523.097 391.828L523.447 381.572L543.019 382.28L541.621 424.362L522.048 423.655L522.398 411.985L465.427 410.217L465.078 425.423H451.447V425.423Z" fill="#A2DEC4"/>
                        <path id="Vector_84" d="M455.291 522.319C454.592 541.769 469.971 558.037 489.194 558.744C508.417 559.451 524.495 543.891 525.194 524.441C525.893 504.991 510.515 488.724 491.291 488.017C472.068 487.663 455.99 502.869 455.291 522.319ZM485.699 527.624C486.398 526.917 487.097 525.856 487.796 524.088C489.194 520.905 490.592 518.429 491.99 517.015C493.388 515.6 495.485 514.893 498.282 514.893C500.728 514.893 502.825 515.954 504.223 517.368C505.621 519.137 506.67 521.258 506.67 524.088H510.864V527.624H506.67C506.32 530.453 505.272 532.575 503.524 534.343C501.777 536.111 499.33 536.818 495.835 536.818L496.184 530.453C498.282 530.453 499.68 530.099 500.379 529.392C501.078 528.685 501.777 527.27 501.777 525.856C501.777 524.441 501.427 523.38 500.728 522.673C500.029 521.966 498.981 521.612 497.932 521.612C496.883 521.612 495.835 521.966 495.136 522.673C494.437 523.38 493.738 524.441 493.039 526.209C491.641 529.392 490.243 531.867 488.845 533.636C487.447 535.05 485.35 535.758 482.553 535.758C480.107 535.758 478.01 534.697 476.612 533.282C475.214 531.514 474.165 529.392 474.165 526.563H469.272V523.027H474.165C474.515 520.198 475.913 518.429 477.66 517.015C479.408 515.6 481.854 514.893 484.651 514.893L484.301 521.258C482.553 521.258 481.155 521.612 480.107 522.319C479.058 523.027 478.709 524.088 478.709 525.148C478.709 526.563 479.058 527.27 479.757 527.978C480.456 528.685 481.505 529.038 482.553 529.038C483.951 528.331 485 528.331 485.699 527.624V527.624Z" fill="#A2DEC4"/>
                        <path id="Vector_85" d="M462.631 282.908C461.932 302.358 477.311 318.625 496.534 319.333C515.757 320.04 531.835 304.48 532.534 285.03C533.233 265.58 517.854 249.313 498.631 248.606C479.408 248.252 463.33 263.458 462.631 282.908ZM493.039 288.213C493.738 287.505 494.437 286.445 495.136 284.676C496.534 281.494 497.932 279.018 499.33 277.604C500.728 276.189 502.825 275.482 505.621 275.482C508.068 275.482 510.165 276.543 511.563 277.957C512.961 279.725 514.01 281.847 514.01 284.676H518.204V288.213H514.01C513.66 291.042 512.612 293.164 510.864 294.932C509.117 296.7 506.67 297.407 503.175 297.407L503.524 291.042C505.621 291.042 507.019 290.688 507.719 289.981C508.418 289.274 509.117 287.859 509.117 286.445C509.117 285.03 508.767 283.969 508.068 283.262C507.369 282.555 506.32 282.201 505.272 282.201C504.223 282.201 503.175 282.555 502.476 283.262C501.777 283.969 501.078 285.03 500.379 286.798C498.981 289.981 497.583 292.456 496.184 294.224C494.786 295.639 492.689 296.346 489.893 296.346C487.447 296.346 485.349 295.285 483.951 293.871C482.553 292.103 481.505 289.981 481.505 287.152H476.612V283.615H481.505C481.854 280.786 483.252 279.018 485 277.604C486.748 276.189 489.194 275.482 491.99 275.482L491.641 281.847C489.893 281.847 488.495 282.201 487.447 282.908C486.398 283.615 486.049 284.676 486.049 285.737C486.049 287.152 486.398 287.859 487.097 288.566C487.796 289.274 488.845 289.627 489.893 289.627C491.291 288.92 492.34 288.92 493.039 288.213V288.213Z" fill="#A2DEC4"/>
                        <path id="Vector_86" d="M425.806 570.956C426.107 561.196 418.531 553.036 408.884 552.731C399.238 552.426 391.173 560.091 390.872 569.852C390.57 579.612 398.146 587.772 407.793 588.077C417.439 588.382 425.504 580.717 425.806 570.956Z" fill="#A2DEC4"/>
                        <path id="Vector_87" d="M586.734 575.972C587.035 566.212 579.459 558.052 569.813 557.747C560.166 557.442 552.101 565.107 551.8 574.868C551.498 584.628 559.074 592.788 568.721 593.093C578.368 593.398 586.432 585.733 586.734 575.972Z" fill="#A2DEC4"/>
                        <path id="Vector_88" d="M436.499 231.661C436.8 221.901 429.224 213.741 419.578 213.436C409.931 213.131 401.866 220.796 401.565 230.557C401.263 240.317 408.839 248.477 418.486 248.782C428.133 249.087 436.197 241.422 436.499 231.661Z" fill="#A2DEC4"/>
                        <path id="Vector_89" d="M597.062 236.638C597.363 226.878 589.787 218.718 580.141 218.413C570.494 218.108 562.429 225.773 562.128 235.534C561.826 245.294 569.402 253.454 579.049 253.759C588.696 254.064 596.76 246.399 597.062 236.638Z" fill="#A2DEC4"/>
                        <path id="Vector_90" d="M558.417 405.3C559.527 369.381 531.648 339.353 496.147 338.231C460.647 337.108 430.97 365.316 429.86 401.235C428.751 437.153 456.63 467.181 492.13 468.304C527.63 469.426 557.308 441.218 558.417 405.3Z" stroke="#8BD6B5" stroke-miterlimit="10"/>
                        <path id="Vector_91" opacity="0.5" d="M604.884 212.181L592.651 601.18L383.291 594.461L395.175 205.462L604.884 212.181Z" fill="url(#paint48_linear)"/>
                    </g>
                    <g id="Note_1">
                        <path id="Vector_92" d="M639.835 188.134L613.272 576.426L404.262 561.927L430.476 173.635L639.835 188.134Z" fill="url(#paint49_linear)"/>
                        <path id="Vector_93" d="M446.928 192.456L422.985 545.278L597.34 557.391L621.284 204.569L446.928 192.456Z" stroke="#A2DEC4" stroke-miterlimit="10"/>
                        <path id="Vector_94" d="M453.424 199.997L430.438 538.706L590.845 549.85L613.831 211.141L453.424 199.997Z" stroke="#A2DEC4" stroke-width="0.5" stroke-miterlimit="10"/>
                        <path id="Vector_95" d="M478.709 395.364L474.515 368.488L475.214 359.293L551.408 364.598L552.107 354.696L571.68 356.111L568.883 398.193L549.311 396.779L550.01 385.109L493.039 381.219L491.99 396.425L478.709 395.364V395.364Z" fill="#A2DEC4"/>
                        <path id="Vector_96" d="M479.058 492.614C477.66 512.064 492.34 529.038 511.563 530.453C530.786 531.868 547.563 517.015 548.961 497.565C550.359 478.115 535.68 461.14 516.456 459.726C496.884 458.311 480.107 473.164 479.058 492.614ZM509.117 498.979C509.816 498.272 510.515 497.211 511.563 495.443C512.961 492.26 514.709 489.785 516.107 488.37C517.505 486.956 519.602 486.249 522.398 486.602C524.845 486.956 526.942 487.663 528.34 489.431C529.738 491.199 530.437 493.321 530.437 496.15L534.631 496.504L534.282 500.04L530.087 499.687C529.738 502.516 528.34 504.638 526.592 506.406C524.845 508.174 522.398 508.881 518.903 508.528L519.252 502.162C521.35 502.162 522.748 501.808 523.447 501.101C524.495 500.394 524.845 498.979 524.845 497.565C524.845 496.15 524.495 495.089 524.146 494.382C523.447 493.675 522.398 493.321 521.35 492.968C520.301 492.968 519.253 493.321 518.204 493.675C517.505 494.382 516.806 495.443 515.757 497.211C514.359 500.394 512.961 502.869 511.214 504.284C509.816 505.699 507.718 506.406 504.922 506.052C502.476 506.052 500.379 504.991 498.981 503.223C497.583 501.455 496.884 499.333 496.534 496.504L491.641 496.15L491.99 492.614L496.884 492.968C497.583 490.492 498.631 488.37 500.379 486.956C502.126 485.541 504.573 485.188 507.719 485.188L507.369 491.553C505.621 491.553 504.223 491.553 503.175 492.26C502.126 492.968 501.777 494.029 501.427 495.089C501.427 496.504 501.777 497.211 502.126 497.919C502.825 498.626 503.874 498.979 504.922 498.979C507.369 499.687 508.418 499.333 509.117 498.979V498.979Z" fill="#A2DEC4"/>
                        <path id="Vector_97" d="M495.136 253.556C493.738 273.006 508.417 289.981 527.641 291.395C546.864 292.81 563.641 277.957 565.039 258.507C566.437 239.057 551.757 222.083 532.534 220.668C513.311 219.254 496.534 234.107 495.136 253.556ZM525.194 259.922C525.893 259.215 526.592 258.154 527.641 256.386C529.039 253.203 530.786 250.727 532.185 249.313C533.583 247.898 535.68 247.191 538.476 247.545C540.922 247.898 543.019 248.606 544.418 250.374C545.816 252.142 546.515 254.264 546.515 257.093L550.709 257.446L550.359 260.983L546.165 260.629C545.816 263.458 544.418 265.58 542.67 267.348C540.922 269.116 538.476 269.824 534.981 269.47L535.33 263.105C537.427 263.105 538.825 262.751 539.524 262.044C540.573 261.336 540.922 259.922 540.922 258.507C540.922 257.093 540.573 256.032 540.223 255.325C539.524 254.617 538.476 254.264 537.427 253.91C536.379 253.91 535.33 254.264 534.282 254.617C533.582 255.325 532.884 256.386 531.835 258.154C530.437 261.336 529.039 263.812 527.291 265.226C525.893 266.641 523.796 267.348 521 266.995C518.553 266.995 516.456 265.934 515.058 264.166C513.66 262.397 512.961 260.276 512.612 257.446L507.719 257.093L508.068 253.556L512.961 253.91C513.66 251.435 514.709 249.313 516.456 247.898C518.204 246.484 520.65 246.13 523.796 246.13L523.447 252.496C521.699 252.496 520.301 252.496 519.252 253.203C518.204 253.91 517.854 254.971 517.505 256.032C517.505 257.446 517.854 258.154 518.204 258.861C518.903 259.568 519.951 259.922 521 259.922C523.447 260.629 524.495 260.629 525.194 259.922V259.922Z" fill="#A2DEC4"/>
                        <path id="Vector_98" d="M447.834 540.123C448.495 530.38 441.225 521.939 431.595 521.27C421.966 520.601 413.624 527.957 412.963 537.7C412.301 547.443 419.572 555.883 429.201 556.552C438.83 557.221 447.172 549.866 447.834 540.123Z" fill="#A2DEC4"/>
                        <path id="Vector_99" d="M608.042 551.22C608.704 541.477 601.433 533.036 591.804 532.367C582.175 531.698 573.832 539.054 573.171 548.797C572.51 558.54 579.78 566.98 589.41 567.649C599.039 568.318 607.381 560.962 608.042 551.22Z" fill="#A2DEC4"/>
                        <path id="Vector_100" d="M470.725 201.344C471.386 191.602 464.115 183.161 454.486 182.492C444.857 181.823 436.515 189.179 435.853 198.922C435.192 208.665 442.463 217.105 452.092 217.774C461.721 218.443 470.063 211.087 470.725 201.344Z" fill="#A2DEC4"/>
                        <path id="Vector_101" d="M630.943 212.444C631.604 202.701 624.334 194.26 614.705 193.591C605.075 192.922 596.733 200.278 596.072 210.021C595.411 219.764 602.681 228.204 612.31 228.873C621.94 229.542 630.282 222.186 630.943 212.444Z" fill="#A2DEC4"/>
                        <path id="Vector_102" d="M586.299 379.382C588.733 343.528 561.978 312.467 526.542 310.005C491.106 307.543 460.407 334.613 457.974 370.466C455.541 406.32 482.295 437.381 517.731 439.843C553.167 442.305 583.866 415.236 586.299 379.382Z" stroke="#8BD6B5" stroke-miterlimit="10"/>
                        <path id="Vector_103" opacity="0.6" d="M639.835 188.134L613.272 576.426L404.262 561.927L430.476 173.635L639.835 188.134Z" fill="url(#paint50_linear)"/>
                    </g>
                    <g id="Wallet_front">
                        <rect id="Rectangle_2" x="276.34" y="380.158" width="377.476" height="297.054" fill="url(#pattern1)"/>
                        <path id="Vector_104" d="M297.311 632.654C297.311 644.324 306.398 653.518 317.932 653.872C407.757 655.994 643.33 669.078 643.33 639.373V384.402C643.33 414.107 409.505 401.376 318.981 398.901C307.097 398.547 297.311 408.095 297.311 420.119V632.654Z" fill="url(#paint51_linear)"/>
                        <path id="Vector_105" opacity="0.2" d="M297.311 504.284H376.301C383.99 504.284 390.282 510.649 390.282 518.429V560.866C390.282 568.646 383.99 575.011 376.301 575.011H297.311V504.284V504.284Z" fill="url(#paint52_linear)"/>
                        <path id="Vector_106" d="M297.311 493.675C297.311 497.565 300.456 500.748 304.301 500.748H373.155C380.845 500.748 387.136 507.113 387.136 514.893V557.329C387.136 565.109 380.845 571.475 373.155 571.475H297.311C293.466 571.475 290.32 568.292 290.32 564.402V500.748C290.32 496.858 293.466 493.675 297.311 493.675Z" fill="url(#paint53_linear)"/>
                        <path id="Vector_107" opacity="0.8" d="M372.806 500.748H304.301C300.456 500.748 297.311 497.565 297.311 493.675C295.913 493.675 294.864 494.029 293.816 494.736V497.211C293.816 501.101 296.961 504.284 300.806 504.284H373.155C380.845 504.284 387.136 510.649 387.136 518.429V514.893C386.786 507.113 380.845 500.748 372.806 500.748Z" fill="url(#paint54_linear)"/>
                        <path id="Vector_108" opacity="0.2" d="M357.078 554.854C364.799 554.854 371.058 548.521 371.058 540.708C371.058 532.896 364.799 526.563 357.078 526.563C349.356 526.563 343.097 532.896 343.097 540.708C343.097 548.521 349.356 554.854 357.078 554.854Z" fill="url(#paint55_linear)"/>
                        <path id="Vector_109" d="M353.583 551.317C361.304 551.317 367.563 544.984 367.563 537.172C367.563 529.36 361.304 523.027 353.583 523.027C345.861 523.027 339.602 529.36 339.602 537.172C339.602 544.984 345.861 551.317 353.583 551.317Z" fill="url(#paint56_linear)"/>
                        <path id="Vector_110" d="M353.583 547.781C359.373 547.781 364.068 543.031 364.068 537.172C364.068 531.313 359.373 526.563 353.583 526.563C347.792 526.563 343.097 531.313 343.097 537.172C343.097 543.031 347.792 547.781 353.583 547.781Z" fill="url(#paint57_linear)"/>
                    </g>
                    <g id="Coins_1">
                        <rect id="Rectangle_3" x="597.893" y="595.876" width="164.272" height="99.0179" fill="url(#pattern2)"/>
                        <path id="Vector_111" d="M680.029 656.701C718.636 656.701 749.932 644.826 749.932 630.178C749.932 615.53 718.636 603.656 680.029 603.656C641.423 603.656 610.126 615.53 610.126 630.178C610.126 644.826 641.423 656.701 680.029 656.701Z" fill="url(#paint58_linear)"/>
                        <path id="Vector_112" d="M680.029 645.738C709.37 645.738 733.155 638.297 733.155 629.117C733.155 619.938 709.37 612.496 680.029 612.496C650.688 612.496 626.903 619.938 626.903 629.117C626.903 638.297 650.688 645.738 680.029 645.738Z" fill="url(#paint59_linear)"/>
                        <path id="Vector_113" d="M680.029 616.033C707.641 616.033 730.01 622.398 732.806 630.885C733.155 630.178 733.155 629.824 733.155 629.117C733.155 619.923 709.388 612.496 680.029 612.496C650.67 612.496 626.903 619.923 626.903 629.117C626.903 629.824 626.903 630.178 627.252 630.885C630.048 622.398 652.417 616.033 680.029 616.033V616.033Z" fill="url(#paint60_linear)"/>
                        <path id="Vector_114" d="M610.126 652.457C610.126 666.956 641.583 678.98 680.029 678.98C718.476 678.98 749.932 666.956 749.932 652.457V630.178C749.932 644.677 718.476 656.701 680.029 656.701C641.583 656.701 610.126 644.677 610.126 630.178V652.457Z" fill="url(#paint61_linear)"/>
                        <path id="Vector_115" d="M654.864 654.933C628.65 651.043 610.126 641.495 610.126 630.178V652.457C610.126 663.774 628.65 673.322 654.864 677.212V654.933Z" fill="url(#paint62_linear)"/>
                        <path id="Vector_116" d="M679.68 656.701H671.291V678.98H679.68V656.701Z" fill="url(#paint63_linear)"/>
                        <path id="Vector_117" d="M710.437 632.654V631.593C710.087 629.117 707.291 627.349 703.796 625.935V622.398V621.691C703.097 617.447 694.359 615.325 686.32 615.325H683.175V614.265C683.175 613.557 682.476 612.85 681.777 612.85H674.437C673.738 612.85 673.039 613.557 673.039 614.265V615.325H670.942C662.204 615.325 652.767 617.801 652.068 622.044V622.752V626.642C652.068 627.349 652.068 628.41 653.116 629.471C653.466 630.178 654.165 630.532 655.214 630.885H652.068H651.718H651.369V631.239V631.593V631.946V632.654V636.544C651.369 637.251 651.369 638.312 652.418 639.373C654.515 641.848 660.456 643.616 667.447 643.616H673.388V646.092C673.388 646.799 674.087 647.506 674.786 647.506H683.524C684.223 647.506 684.922 646.799 684.922 646.092V643.616H693.311C701.699 643.616 711.485 641.494 712.534 636.544V636.19C710.437 636.897 710.437 635.836 710.437 632.654V632.654ZM665.699 635.483H673.039L673.388 628.41H669.544C659.757 628.41 652.068 625.581 652.767 622.398C653.466 619.215 661.155 616.74 670.243 616.74H673.738V614.265H681.078V616.74H685.621C694.01 616.74 701.349 619.215 702.048 622.044H694.359C694.359 621.337 693.66 620.984 692.961 620.63C691.563 619.923 688.767 619.215 685.971 619.215H681.427V625.581H690.515C700.301 625.581 708.689 628.41 709.039 631.946C709.738 635.483 701.699 638.665 691.214 638.665H681.427V642.555H672.689V638.665H665.349C656.961 638.665 650.32 636.19 650.67 633.007H658.709C659.058 634.068 661.854 635.483 665.699 635.483V635.483Z" fill="url(#paint64_linear)"/>
                        <path id="Vector_118" d="M651.019 632.654C650.67 635.836 656.961 638.312 665.699 638.312H673.039V642.202H681.777V638.312H691.563C702.048 638.312 710.087 635.129 709.388 631.593C708.689 628.056 700.301 625.227 690.864 625.227H681.777V618.862H686.32C689.116 618.862 691.913 619.215 693.311 620.276C694.359 620.63 694.709 621.337 694.709 621.691H702.398C702.049 618.862 694.709 616.386 685.971 616.386H681.427V613.911H674.087V616.386H670.592C661.505 616.386 653.466 618.862 653.116 622.045C652.417 625.227 660.107 628.056 669.893 628.056H673.738L673.388 635.129H666.049C662.204 635.129 659.058 634.068 659.408 632.654H651.019V632.654ZM696.107 628.764C698.903 629.471 700.65 630.532 701 631.593C701.35 633.715 696.806 635.129 691.214 635.129H681.777V628.056H690.864C692.612 628.056 694.359 628.41 696.107 628.764ZM660.456 622.398C660.456 621.691 661.854 620.63 663.602 620.276C664.65 619.923 665.35 619.923 666.748 619.569C667.796 619.569 669.194 619.215 670.243 619.215H673.738L673.388 625.581H669.544C664.301 625.581 660.107 624.166 660.456 622.398V622.398Z" fill="#FCD224"/>
                        <path id="Vector_119" d="M673.388 625.581L673.738 619.215H670.243C668.845 619.215 667.796 619.215 666.748 619.569C665.699 619.569 664.65 619.923 663.602 620.276C661.854 620.984 660.806 621.691 660.456 622.398C660.107 624.166 664.301 625.581 669.893 625.581H673.388V625.581Z" fill="url(#paint65_linear)"/>
                        <path id="Vector_120" d="M681.427 635.483H690.864C696.456 635.483 701 633.715 700.651 631.946C700.651 630.886 698.553 629.825 695.757 629.117C694.01 628.764 692.262 628.41 690.165 628.41H681.078L681.427 635.483Z" fill="url(#paint66_linear)"/>
                        <g id="Group_3" opacity="0.1">
                            <path id="Vector_121" opacity="0.1" d="M687.019 632.3C725.626 632.3 756.922 620.425 756.922 605.777C756.922 591.129 725.626 579.255 687.019 579.255C648.413 579.255 617.116 591.129 617.116 605.777C617.116 620.425 648.413 632.3 687.019 632.3Z" fill="black"/>
                            <path id="Vector_122" opacity="0.1" d="M687.019 621.337C716.36 621.337 740.146 613.896 740.146 604.716C740.146 595.537 716.36 588.096 687.019 588.096C657.679 588.096 633.893 595.537 633.893 604.716C633.893 613.896 657.679 621.337 687.019 621.337Z" fill="black"/>
                            <path id="Vector_123" opacity="0.1" d="M687.019 591.985C714.631 591.985 737 598.351 739.796 606.838C740.146 606.131 740.146 605.777 740.146 605.07C740.146 595.876 716.379 588.449 687.019 588.449C657.66 588.449 633.893 595.876 633.893 605.07C633.893 605.777 633.893 606.131 634.243 606.838C637.039 598.351 659.408 591.985 687.019 591.985V591.985Z" fill="black"/>
                            <path id="Vector_124" opacity="0.1" d="M617.116 628.056C617.116 642.555 648.573 654.579 687.019 654.579C725.466 654.579 756.922 642.555 756.922 628.056V605.777C756.922 620.276 725.466 632.3 687.019 632.3C648.573 632.3 617.116 620.276 617.116 605.777V628.056Z" fill="black"/>
                            <path id="Vector_125" opacity="0.1" d="M661.854 630.532C635.641 626.642 617.116 617.094 617.116 605.777V628.056C617.116 639.373 635.641 648.921 661.854 652.811V630.532Z" fill="black"/>
                            <path id="Vector_126" opacity="0.1" d="M686.67 632.653H678.282V654.932H686.67V632.653Z" fill="black"/>
                            <path id="Vector_127" opacity="0.1" d="M717.427 608.253V607.192C717.078 604.716 714.282 602.948 710.786 601.534V597.997V597.29C710.087 593.046 701.349 590.925 693.311 590.925H690.165V589.864C690.165 589.156 689.466 588.449 688.767 588.449H681.427C680.728 588.449 680.029 589.156 680.029 589.864V590.925H677.932C669.194 590.925 659.757 593.4 659.058 597.644V598.351V602.241C659.058 602.948 659.058 604.009 660.107 605.07C660.456 605.777 661.155 606.131 662.204 606.485H659.058H658.709H658.359V606.838V607.192V607.545V608.253V612.143C658.359 612.85 658.359 613.911 659.408 614.972C661.505 617.447 667.447 619.215 674.437 619.215H680.379V621.691C680.379 622.398 681.078 623.105 681.777 623.105H690.515C691.214 623.105 691.913 622.398 691.913 621.691V619.215H700.301C708.689 619.215 718.476 617.094 719.524 612.143V611.789C717.427 612.85 717.427 611.789 717.427 608.253V608.253ZM672.689 611.082H680.029L680.379 604.009H676.534C666.748 604.009 659.058 601.18 659.757 597.997C660.456 594.815 668.146 592.339 677.233 592.339H680.728V589.864H688.068V592.339H692.612C701 592.339 708.34 594.815 709.039 597.644H701.35C701.35 596.936 700.651 596.583 699.952 596.229C698.553 595.522 695.757 594.815 692.961 594.815H688.417V601.18H697.505C707.291 601.18 715.68 604.009 716.029 607.545C716.728 611.082 708.689 614.265 698.204 614.265H688.417V618.155H679.68V614.265H672.34C663.951 614.265 657.311 611.789 657.66 608.606H665.699C666.048 610.021 668.845 611.082 672.689 611.082V611.082Z" fill="black"/>
                            <path id="Vector_128" opacity="0.1" d="M658.01 608.606C657.66 611.789 663.951 614.265 672.689 614.265H680.029V618.155H688.767V614.265H698.553C709.039 614.265 717.078 611.082 716.379 607.545C715.68 604.009 707.291 601.18 697.854 601.18H688.767V594.815H693.311C696.107 594.815 698.903 595.168 700.301 596.229C701.349 596.583 701.699 597.29 701.699 597.644H709.388C709.039 594.815 701.699 592.339 692.961 592.339H688.417V589.864H681.078V592.339H677.582C668.495 592.339 660.456 594.815 660.107 597.997C659.408 601.18 667.097 604.009 676.883 604.009H680.728L680.379 611.082H673.039C669.194 611.082 666.049 610.021 666.398 608.606H658.01V608.606ZM703.097 604.716C705.893 605.424 707.641 606.485 707.99 607.545C708.34 609.667 703.796 611.082 698.204 611.082H688.767V604.009H697.854C699.602 604.009 701.349 604.363 703.097 604.716ZM667.447 597.997C667.447 597.29 668.845 596.229 670.592 595.875C671.641 595.522 672.34 595.522 673.738 595.168C674.786 595.168 676.184 594.815 677.233 594.815H680.728L680.379 601.18H676.534C671.291 601.534 667.097 599.765 667.447 597.997V597.997Z" fill="black"/>
                            <path id="Vector_129" opacity="0.1" d="M680.379 601.534L680.728 595.168H677.233C675.835 595.168 674.786 595.168 673.738 595.522C672.689 595.522 671.641 595.875 670.592 596.229C668.845 596.936 667.796 597.644 667.447 598.351C667.097 600.119 671.291 601.534 676.883 601.534H680.379V601.534Z" fill="black"/>
                            <path id="Vector_130" opacity="0.1" d="M688.417 611.082H697.854C703.447 611.082 707.99 609.314 707.641 607.545C707.641 606.485 705.544 605.424 702.748 604.716C701 604.363 699.252 604.009 697.155 604.009H688.068L688.417 611.082Z" fill="black"/>
                        </g>
                        <path id="Vector_131" d="M687.019 628.764C725.626 628.764 756.922 616.889 756.922 602.241C756.922 587.593 725.626 575.718 687.019 575.718C648.413 575.718 617.116 587.593 617.116 602.241C617.116 616.889 648.413 628.764 687.019 628.764Z" fill="url(#paint67_linear)"/>
                        <path id="Vector_132" d="M687.019 617.801C716.36 617.801 740.146 610.359 740.146 601.18C740.146 592.001 716.36 584.559 687.019 584.559C657.679 584.559 633.893 592.001 633.893 601.18C633.893 610.359 657.679 617.801 687.019 617.801Z" fill="url(#paint68_linear)"/>
                        <path id="Vector_133" d="M687.019 588.449C714.631 588.449 737 594.815 739.796 603.302C740.146 602.595 740.146 602.241 740.146 601.534C740.146 592.339 716.379 584.913 687.019 584.913C657.66 584.913 633.893 592.339 633.893 601.534C633.893 602.241 633.893 602.595 634.243 603.302C637.039 594.815 659.408 588.449 687.019 588.449V588.449Z" fill="url(#paint69_linear)"/>
                        <path id="Vector_134" d="M617.116 624.52C617.116 639.019 648.573 651.043 687.019 651.043C725.466 651.043 756.922 639.019 756.922 624.52V602.241C756.922 616.74 725.466 628.764 687.019 628.764C648.573 628.764 617.116 616.74 617.116 602.241V624.52Z" fill="url(#paint70_linear)"/>
                        <path id="Vector_135" d="M661.854 626.995C635.641 623.105 617.116 613.557 617.116 602.241V624.52C617.116 635.836 635.641 645.384 661.854 649.274V626.995Z" fill="url(#paint71_linear)"/>
                        <path id="Vector_136" d="M686.67 629.117H678.282V651.396H686.67V629.117Z" fill="url(#paint72_linear)"/>
                        <path id="Vector_137" d="M717.427 604.716V603.655C717.078 601.18 714.282 599.412 710.786 597.997V594.461V593.754C710.087 589.51 701.349 587.388 693.311 587.388H690.165V586.327C690.165 585.62 689.466 584.913 688.767 584.913H681.427C680.728 584.913 680.029 585.62 680.029 586.327V587.388H677.932C669.194 587.388 659.757 589.864 659.058 594.107V594.815V598.705C659.058 599.412 659.058 600.473 660.107 601.534C660.456 602.241 661.155 602.595 662.204 602.948H659.058H658.709H658.359V603.302V603.655V604.009V604.716V608.606C658.359 609.314 658.359 610.375 659.408 611.435C661.505 613.911 667.447 615.679 674.437 615.679H680.379V618.155C680.379 618.862 681.078 619.569 681.777 619.569H690.515C691.214 619.569 691.913 618.862 691.913 618.155V615.679H700.301C708.689 615.679 718.476 613.557 719.524 608.606V608.253C717.427 609.314 717.427 608.253 717.427 604.716V604.716ZM672.689 607.545H680.029L680.379 600.473H676.534C666.748 600.473 659.058 597.644 659.757 594.461C660.456 591.278 668.146 588.803 677.233 588.803H680.728V586.327H688.068V588.803H692.612C701 588.803 708.34 591.278 709.039 594.107H701.35C701.35 593.4 700.651 593.046 699.952 592.693C698.553 591.986 695.757 591.278 692.961 591.278H688.417V597.644H697.505C707.291 597.644 715.68 600.473 716.029 604.009C716.728 607.545 708.689 610.728 698.204 610.728H688.417V614.618H679.68V610.728H672.34C663.951 610.728 657.311 608.253 657.66 605.07H665.699C666.048 606.485 668.845 607.545 672.689 607.545V607.545Z" fill="url(#paint73_linear)"/>
                        <path id="Vector_138" d="M658.01 605.07C657.66 608.253 663.951 610.728 672.689 610.728H680.029V614.618H688.767V610.728H698.553C709.039 610.728 717.078 607.545 716.379 604.009C715.68 600.473 707.291 597.644 697.854 597.644H688.767V591.278H693.311C696.107 591.278 698.903 591.632 700.301 592.693C701.349 593.046 701.699 593.754 701.699 594.107H709.388C709.039 591.278 701.699 588.803 692.961 588.803H688.417V586.327H681.078V588.803H677.582C668.495 588.803 660.456 591.278 660.107 594.461C659.408 597.644 667.097 600.473 676.883 600.473H680.728L680.379 607.545H673.039C669.194 607.545 666.049 606.485 666.398 605.07H658.01V605.07ZM703.097 601.18C705.893 601.887 707.641 602.948 707.99 604.009C708.34 606.131 703.796 607.545 698.204 607.545H688.767V600.473H697.854C699.602 600.473 701.349 600.826 703.097 601.18ZM667.447 594.461C667.447 593.754 668.845 592.693 670.592 592.339C671.641 591.985 672.34 591.986 673.738 591.632C674.786 591.632 676.184 591.278 677.233 591.278H680.728L680.379 597.644H676.534C671.291 597.997 667.097 596.229 667.447 594.461V594.461Z" fill="#FCD224"/>
                        <path id="Vector_139" d="M680.379 597.997L680.728 591.632H677.233C675.835 591.632 674.786 591.632 673.738 591.985C672.689 591.985 671.641 592.339 670.592 592.693C668.845 593.4 667.796 594.107 667.447 594.815C667.097 596.583 671.291 597.997 676.883 597.997H680.379V597.997Z" fill="url(#paint74_linear)"/>
                        <path id="Vector_140" d="M688.417 607.545H697.854C703.447 607.545 707.99 605.777 707.641 604.009C707.641 602.948 705.544 601.887 702.748 601.18C701 600.826 699.252 600.473 697.155 600.473H688.068L688.417 607.545Z" fill="url(#paint75_linear)"/>
                        <g id="Group_4" opacity="0.1">
                            <path id="Vector_141" opacity="0.1" d="M680.029 602.948C718.636 602.948 749.932 591.074 749.932 576.426C749.932 561.778 718.636 549.903 680.029 549.903C641.423 549.903 610.126 561.778 610.126 576.426C610.126 591.074 641.423 602.948 680.029 602.948Z" fill="black"/>
                            <path id="Vector_142" opacity="0.1" d="M680.029 591.986C709.37 591.986 733.155 584.544 733.155 575.365C733.155 566.185 709.37 558.744 680.029 558.744C650.688 558.744 626.903 566.185 626.903 575.365C626.903 584.544 650.688 591.986 680.029 591.986Z" fill="black"/>
                            <path id="Vector_143" opacity="0.1" d="M680.029 562.28C707.641 562.28 730.01 568.646 732.806 577.133C733.155 576.426 733.155 576.072 733.155 575.365C733.155 566.17 709.388 558.744 680.029 558.744C650.67 558.744 626.903 566.17 626.903 575.365C626.903 576.072 626.903 576.426 627.252 577.133C630.048 568.646 652.417 562.28 680.029 562.28V562.28Z" fill="black"/>
                            <path id="Vector_144" opacity="0.1" d="M610.126 598.705C610.126 613.204 641.583 625.227 680.029 625.227C718.476 625.227 749.932 613.204 749.932 598.705V576.426C749.932 590.925 718.476 602.948 680.029 602.948C641.583 602.948 610.126 590.925 610.126 576.426V598.705Z" fill="black"/>
                            <path id="Vector_145" opacity="0.1" d="M654.864 601.18C628.65 597.29 610.126 587.742 610.126 576.426V598.705C610.126 610.021 628.65 619.569 654.864 623.459V601.18Z" fill="black"/>
                            <path id="Vector_146" opacity="0.1" d="M679.68 602.948H671.291V625.227H679.68V602.948Z" fill="black"/>
                            <path id="Vector_147" opacity="0.1" d="M710.437 578.901V577.84C710.087 575.365 707.291 573.597 703.796 572.182V568.646V567.938C703.097 563.695 694.359 561.573 686.32 561.573H683.175V560.512C683.175 559.805 682.476 559.098 681.777 559.098H674.437C673.738 559.098 673.039 559.805 673.039 560.512V561.573H670.942C662.204 561.573 652.767 564.048 652.068 568.292V568.999V572.889C652.068 573.597 652.068 574.657 653.116 575.718C653.466 576.426 654.165 576.779 655.214 577.133H652.068H651.718H651.369V577.487V577.84V578.194V578.901V582.791C651.369 583.498 651.369 584.559 652.418 585.62C654.515 588.096 660.456 589.864 667.447 589.864H673.388V592.339C673.388 593.046 674.087 593.754 674.786 593.754H683.524C684.223 593.754 684.922 593.046 684.922 592.339V589.864H693.311C701.699 589.864 711.485 587.742 712.534 582.791V582.437C710.437 583.145 710.437 582.437 710.437 578.901V578.901ZM665.699 581.73H673.039L673.388 574.657H669.544C659.757 574.657 652.068 571.828 652.767 568.646C653.466 565.463 661.155 562.987 670.243 562.987H673.738V560.512H681.078V562.987H685.621C694.01 562.987 701.349 565.463 702.048 568.292H694.359C694.359 567.585 693.66 567.231 692.961 566.877C691.563 566.17 688.767 565.463 685.971 565.463H681.427V571.828H690.515C700.301 571.828 708.689 574.657 709.039 578.194C709.738 581.73 701.699 584.913 691.214 584.913H681.427V588.803H672.689V584.913H665.349C656.961 584.913 650.32 582.437 650.67 579.255H658.709C659.058 580.316 661.854 581.73 665.699 581.73V581.73Z" fill="black"/>
                            <path id="Vector_148" opacity="0.1" d="M651.019 579.255C650.67 582.437 656.961 584.913 665.699 584.913H673.039V588.803H681.777V584.913H691.563C702.048 584.913 710.087 581.73 709.388 578.194C708.689 574.657 700.301 571.828 690.864 571.828H681.777V565.463H686.32C689.116 565.463 691.913 565.817 693.311 566.877C694.359 567.231 694.709 567.938 694.709 568.292H702.398C702.049 565.463 694.709 562.987 685.971 562.987H681.427V560.512H674.087V562.987H670.592C661.505 562.987 653.466 565.463 653.116 568.646C652.417 571.828 660.107 574.657 669.893 574.657H673.738L673.388 581.73H666.049C662.204 581.73 659.058 580.669 659.408 579.255H651.019V579.255ZM696.107 575.011C698.903 575.718 700.65 576.779 701 577.84C701.35 579.962 696.806 581.376 691.214 581.376H681.777V574.304H690.864C692.612 574.657 694.359 574.657 696.107 575.011ZM660.456 568.646C660.456 567.938 661.854 566.877 663.602 566.524C664.65 566.17 665.35 566.17 666.748 565.817C667.796 565.817 669.194 565.463 670.243 565.463H673.738L673.388 571.828H669.544C664.301 571.828 660.107 570.414 660.456 568.646V568.646Z" fill="black"/>
                            <path id="Vector_149" opacity="0.1" d="M673.388 571.828L673.738 565.463H670.243C668.845 565.463 667.796 565.463 666.748 565.817C665.699 565.817 664.65 566.17 663.602 566.524C661.854 567.231 660.806 567.938 660.456 568.646C660.107 570.414 664.301 571.828 669.893 571.828H673.388V571.828Z" fill="black"/>
                            <path id="Vector_150" opacity="0.1" d="M681.427 581.73H690.864C696.456 581.73 701 579.962 700.651 578.194C700.651 577.133 698.553 576.072 695.757 575.365C694.01 575.011 692.262 574.657 690.165 574.657H681.078L681.427 581.73Z" fill="black"/>
                        </g>
                        <path id="Vector_151" d="M680.029 599.412C718.636 599.412 749.932 587.537 749.932 572.889C749.932 558.241 718.636 546.367 680.029 546.367C641.423 546.367 610.126 558.241 610.126 572.889C610.126 587.537 641.423 599.412 680.029 599.412Z" fill="url(#paint76_linear)"/>
                        <path id="Vector_152" d="M680.029 588.449C709.37 588.449 733.155 581.008 733.155 571.828C733.155 562.649 709.37 555.207 680.029 555.207C650.688 555.207 626.903 562.649 626.903 571.828C626.903 581.008 650.688 588.449 680.029 588.449Z" fill="url(#paint77_linear)"/>
                        <path id="Vector_153" d="M680.029 558.744C707.641 558.744 730.01 565.109 732.806 573.597C733.155 572.889 733.155 572.536 733.155 571.828C733.155 562.634 709.388 555.207 680.029 555.207C650.67 555.207 626.903 562.634 626.903 571.828C626.903 572.536 626.903 572.889 627.252 573.597C630.048 565.109 652.417 558.744 680.029 558.744V558.744Z" fill="url(#paint78_linear)"/>
                        <path id="Vector_154" d="M610.126 595.168C610.126 609.667 641.583 621.691 680.029 621.691C718.476 621.691 749.932 609.667 749.932 595.168V572.889C749.932 587.388 718.476 599.412 680.029 599.412C641.583 599.412 610.126 587.388 610.126 572.889V595.168Z" fill="url(#paint79_linear)"/>
                        <path id="Vector_155" d="M654.864 597.644C628.65 593.754 610.126 584.206 610.126 572.889V595.168C610.126 606.485 628.65 616.033 654.864 619.923V597.644Z" fill="url(#paint80_linear)"/>
                        <path id="Vector_156" d="M679.68 599.412H671.291V621.691H679.68V599.412Z" fill="url(#paint81_linear)"/>
                        <path id="Vector_157" d="M710.437 575.365V574.304C710.087 571.828 707.291 570.06 703.796 568.646V565.109V564.402C703.097 560.158 694.359 558.037 686.32 558.037H683.175V556.976C683.175 556.268 682.476 555.561 681.777 555.561H674.437C673.738 555.561 673.039 556.268 673.039 556.976V558.037H670.942C662.204 558.037 652.767 560.512 652.068 564.756V565.463V569.353C652.068 570.06 652.068 571.121 653.116 572.182C653.466 572.889 654.165 573.243 655.214 573.596H652.068H651.718H651.369V573.95V574.304V574.657V575.365V579.255C651.369 579.962 651.369 581.023 652.418 582.084C654.515 584.559 660.456 586.327 667.447 586.327H673.388V588.803C673.388 589.51 674.087 590.217 674.786 590.217H683.524C684.223 590.217 684.922 589.51 684.922 588.803V586.327H693.311C701.699 586.327 711.485 584.206 712.534 579.255V578.901C710.437 579.608 710.437 578.901 710.437 575.365V575.365ZM665.699 578.194H673.039L673.388 571.121H669.544C659.757 571.121 652.068 568.292 652.767 565.109C653.466 561.927 661.155 559.451 670.243 559.451H673.738V556.976H681.078V559.451H685.621C694.01 559.451 701.349 561.926 702.048 564.756H694.359C694.359 564.048 693.66 563.695 692.961 563.341C691.563 562.634 688.767 561.926 685.971 561.926H681.427V568.292H690.515C700.301 568.292 708.689 571.121 709.039 574.657C709.738 578.194 701.699 581.376 691.214 581.376H681.427V585.266H672.689V581.376H665.349C656.961 581.376 650.32 578.901 650.67 575.718H658.709C659.058 576.779 661.854 578.194 665.699 578.194V578.194Z" fill="url(#paint82_linear)"/>
                        <path id="Vector_158" d="M651.019 575.718C650.67 578.901 656.961 581.376 665.699 581.376H673.039V585.266H681.777V581.376H691.563C702.048 581.376 710.087 578.194 709.388 574.657C708.689 571.121 700.301 568.292 690.864 568.292H681.777V561.927H686.32C689.116 561.927 691.913 562.28 693.311 563.341C694.359 563.695 694.709 564.402 694.709 564.756H702.398C702.049 561.927 694.709 559.451 685.971 559.451H681.427V556.976H674.087V559.451H670.592C661.505 559.451 653.466 561.927 653.116 565.109C652.417 568.292 660.107 571.121 669.893 571.121H673.738L673.388 578.194H666.049C662.204 578.194 659.058 577.133 659.408 575.718H651.019V575.718ZM696.107 571.475C698.903 572.182 700.65 573.243 701 574.304C701.35 576.426 696.806 577.84 691.214 577.84H681.777V570.767H690.864C692.612 571.121 694.359 571.121 696.107 571.475ZM660.456 565.109C660.456 564.402 661.854 563.341 663.602 562.987C664.65 562.634 665.35 562.634 666.748 562.28C667.796 562.28 669.194 561.927 670.243 561.927H673.738L673.388 568.292H669.544C664.301 568.292 660.107 566.877 660.456 565.109V565.109Z" fill="#FCD224"/>
                        <path id="Vector_159" d="M673.388 568.292L673.738 561.927H670.243C668.845 561.927 667.796 561.927 666.748 562.28C665.699 562.28 664.65 562.634 663.602 562.987C661.854 563.695 660.806 564.402 660.456 565.109C660.107 566.877 664.301 568.292 669.893 568.292H673.388V568.292Z" fill="url(#paint83_linear)"/>
                        <path id="Vector_160" d="M681.427 578.194H690.864C696.456 578.194 701 576.426 700.651 574.657C700.651 573.597 698.553 572.536 695.757 571.828C694.01 571.475 692.262 571.121 690.165 571.121H681.078L681.427 578.194Z" fill="url(#paint84_linear)"/>
                        <g id="Group_5" opacity="0.1">
                            <path id="Vector_161" opacity="0.1" d="M655.563 575.365C694.169 575.365 725.466 563.49 725.466 548.842C725.466 534.194 694.169 522.319 655.563 522.319C616.957 522.319 585.66 534.194 585.66 548.842C585.66 563.49 616.957 575.365 655.563 575.365Z" fill="black"/>
                            <path id="Vector_162" opacity="0.1" d="M655.563 564.048C684.904 564.048 708.689 556.607 708.689 547.428C708.689 538.248 684.904 530.807 655.563 530.807C626.222 530.807 602.437 538.248 602.437 547.428C602.437 556.607 626.222 564.048 655.563 564.048Z" fill="black"/>
                            <path id="Vector_163" opacity="0.1" d="M655.563 534.697C683.175 534.697 705.544 541.062 708.34 549.549C708.689 548.842 708.689 548.488 708.689 547.781C708.689 538.587 684.922 531.16 655.563 531.16C626.204 531.16 602.437 538.587 602.437 547.781C602.437 548.488 602.437 548.842 602.786 549.549C605.583 541.062 627.952 534.697 655.563 534.697V534.697Z" fill="black"/>
                            <path id="Vector_164" opacity="0.1" d="M585.66 570.768C585.66 585.267 617.116 597.29 655.563 597.29C694.01 597.29 725.466 585.267 725.466 570.768V548.489C725.466 562.988 694.01 575.011 655.563 575.011C617.116 575.011 585.66 562.988 585.66 548.489V570.768Z" fill="black"/>
                            <path id="Vector_165" opacity="0.1" d="M630.398 573.597C604.184 569.707 585.66 560.158 585.66 548.842V571.121C585.66 582.437 604.184 591.986 630.398 595.876V573.597Z" fill="black"/>
                            <path id="Vector_166" opacity="0.1" d="M655.214 575.365H646.825V597.644H655.214V575.365Z" fill="black"/>
                            <path id="Vector_167" opacity="0.1" d="M685.971 550.964V549.903C685.621 547.428 682.825 545.659 679.33 544.245V540.708V540.001C678.631 535.758 669.893 533.636 661.854 533.636H658.709V532.575C658.709 531.868 658.01 531.16 657.311 531.16H649.971C649.272 531.16 648.573 531.868 648.573 532.575V533.636H646.476C637.738 533.636 628.301 536.111 627.602 540.355V541.062V544.952C627.602 545.659 627.602 546.72 628.65 547.781C629 548.488 629.699 548.842 630.748 549.196H627.602H627.252H626.903V549.549V549.903V550.257V550.964V554.854C626.903 555.561 626.903 556.622 627.951 557.683C630.049 560.158 635.99 561.927 642.981 561.927H648.922V564.402C648.922 565.109 649.621 565.817 650.32 565.817H659.058C659.757 565.817 660.456 565.109 660.456 564.402V561.927H668.845C677.233 561.927 687.019 559.805 688.068 554.854V554.5C685.971 555.561 685.971 554.5 685.971 550.964V550.964ZM641.233 553.793H648.573L648.922 546.72H645.078C635.291 546.72 627.602 543.891 628.301 540.708C629 537.526 636.689 535.05 645.777 535.05H649.272V532.575H656.612V535.05H661.155C669.544 535.05 676.883 537.526 677.582 540.355H669.893C669.893 539.648 669.194 539.294 668.495 538.94C667.097 538.233 664.301 537.526 661.505 537.526H656.961V543.891H666.048C675.835 543.891 684.223 546.72 684.573 550.257C685.272 553.793 677.233 556.976 666.748 556.976H656.961V560.866H648.223V556.976H640.883C632.495 556.976 625.854 554.5 626.204 551.318H634.243C634.592 552.732 637.388 553.793 641.233 553.793V553.793Z" fill="black"/>
                            <path id="Vector_168" opacity="0.1" d="M626.553 551.318C626.204 554.5 632.495 556.976 641.233 556.976H648.573V560.866H657.311V556.976H667.097C677.583 556.976 685.621 553.793 684.922 550.257C684.223 546.72 675.835 543.891 666.398 543.891H657.311V537.526H661.854C664.65 537.526 667.447 537.879 668.845 538.94C669.893 539.294 670.243 540.001 670.243 540.355H677.932C677.583 537.526 670.243 535.05 661.505 535.05H656.961V532.575H649.621V535.05H646.126C637.039 535.05 629 537.526 628.65 540.709C627.951 543.891 635.641 546.72 645.427 546.72H649.272L648.922 553.793H641.583C637.738 553.793 634.592 552.732 634.942 551.318H626.553V551.318ZM671.641 547.428C674.437 548.135 676.185 549.196 676.534 550.257C676.884 552.379 672.34 553.793 666.748 553.793H657.311V546.72H666.398C668.146 546.72 669.893 547.074 671.641 547.428ZM635.99 540.709C635.99 540.001 637.388 538.94 639.136 538.587C640.184 538.233 640.884 538.233 642.282 537.879C643.33 537.879 644.728 537.526 645.777 537.526H649.272L648.922 543.891H645.078C639.835 544.245 635.641 542.477 635.99 540.709V540.709Z" fill="black"/>
                            <path id="Vector_169" opacity="0.1" d="M648.922 544.245L649.272 537.879H645.777C644.379 537.879 643.33 537.879 642.282 538.233C641.233 538.233 640.184 538.587 639.136 538.94C637.388 539.648 636.34 540.355 635.99 541.062C635.641 542.83 639.835 544.245 645.427 544.245H648.922V544.245Z" fill="black"/>
                            <path id="Vector_170" opacity="0.1" d="M656.961 554.147H666.398C671.99 554.147 676.534 552.378 676.184 550.61C676.184 549.549 674.087 548.488 671.291 547.781C669.544 547.428 667.796 547.074 665.699 547.074H656.612L656.961 554.147Z" fill="black"/>
                        </g>
                        <path id="Vector_171" d="M655.563 571.828C694.169 571.828 725.466 559.954 725.466 545.306C725.466 530.658 694.169 518.783 655.563 518.783C616.957 518.783 585.66 530.658 585.66 545.306C585.66 559.954 616.957 571.828 655.563 571.828Z" fill="url(#paint85_linear)"/>
                        <path id="Vector_172" d="M655.563 560.512C684.904 560.512 708.689 553.071 708.689 543.891C708.689 534.712 684.904 527.27 655.563 527.27C626.222 527.27 602.437 534.712 602.437 543.891C602.437 553.071 626.222 560.512 655.563 560.512Z" fill="url(#paint86_linear)"/>
                        <path id="Vector_173" d="M655.563 531.16C683.175 531.16 705.544 537.526 708.34 546.013C708.689 545.306 708.689 544.952 708.689 544.245C708.689 535.05 684.922 527.624 655.563 527.624C626.204 527.624 602.437 535.05 602.437 544.245C602.437 544.952 602.437 545.306 602.786 546.013C605.583 537.526 627.952 531.16 655.563 531.16V531.16Z" fill="url(#paint87_linear)"/>
                        <path id="Vector_174" d="M585.66 567.231C585.66 581.73 617.116 593.754 655.563 593.754C694.01 593.754 725.466 581.73 725.466 567.231V544.952C725.466 559.451 694.01 571.475 655.563 571.475C617.116 571.475 585.66 559.451 585.66 544.952V567.231Z" fill="url(#paint88_linear)"/>
                        <path id="Vector_175" d="M630.398 570.06C604.184 566.17 585.66 556.622 585.66 545.306V567.585C585.66 578.901 604.184 588.449 630.398 592.339V570.06Z" fill="url(#paint89_linear)"/>
                        <path id="Vector_176" d="M655.214 571.828H646.825V594.107H655.214V571.828Z" fill="url(#paint90_linear)"/>
                        <path id="Vector_177" d="M685.971 547.428V546.367C685.621 543.891 682.825 542.123 679.33 540.708V537.172V536.465C678.631 532.221 669.893 530.099 661.854 530.099H658.709V529.039C658.709 528.331 658.01 527.624 657.311 527.624H649.971C649.272 527.624 648.573 528.331 648.573 529.039V530.099H646.476C637.738 530.099 628.301 532.575 627.602 536.818V537.526V541.416C627.602 542.123 627.602 543.184 628.65 544.245C629 544.952 629.699 545.306 630.748 545.659H627.602H627.252H626.903V546.013V546.367V546.72V547.428V551.318C626.903 552.025 626.903 553.086 627.951 554.147C630.049 556.622 635.99 558.39 642.981 558.39H648.922V560.866C648.922 561.573 649.621 562.28 650.32 562.28H659.058C659.757 562.28 660.456 561.573 660.456 560.866V558.39H668.845C677.233 558.39 687.019 556.268 688.068 551.318V550.964C685.971 552.025 685.971 550.964 685.971 547.428V547.428ZM641.233 550.257H648.573L648.922 543.184H645.078C635.291 543.184 627.602 540.355 628.301 537.172C629 533.989 636.689 531.514 645.777 531.514H649.272V529.039H656.612V531.514H661.155C669.544 531.514 676.883 533.989 677.582 536.818H669.893C669.893 536.111 669.194 535.758 668.495 535.404C667.097 534.697 664.301 533.989 661.505 533.989H656.961V540.355H666.048C675.835 540.355 684.223 543.184 684.573 546.72C685.272 550.257 677.233 553.439 666.748 553.439H656.961V557.329H648.223V553.439H640.883C632.495 553.439 625.854 550.964 626.204 547.781H634.243C634.592 549.196 637.388 550.257 641.233 550.257V550.257Z" fill="url(#paint91_linear)"/>
                        <path id="Vector_178" d="M626.553 547.781C626.204 550.964 632.495 553.439 641.233 553.439H648.573V557.329H657.311V553.439H667.097C677.583 553.439 685.621 550.257 684.922 546.72C684.223 543.184 675.835 540.355 666.398 540.355H657.311V533.989H661.854C664.65 533.989 667.447 534.343 668.845 535.404C669.893 535.757 670.243 536.465 670.243 536.818H677.932C677.583 533.989 670.243 531.514 661.505 531.514H656.961V529.038H649.621V531.514H646.126C637.039 531.514 629 533.989 628.65 537.172C627.951 540.355 635.641 543.184 645.427 543.184H649.272L648.922 550.257H641.583C637.738 550.257 634.592 549.196 634.942 547.781H626.553V547.781ZM671.641 543.891C674.437 544.598 676.185 545.659 676.534 546.72C676.884 548.842 672.34 550.257 666.748 550.257H657.311V543.184H666.398C668.146 543.184 669.893 543.538 671.641 543.891ZM635.99 537.172C635.99 536.465 637.388 535.404 639.136 535.05C640.184 534.697 640.884 534.697 642.282 534.343C643.33 534.343 644.728 533.989 645.777 533.989H649.272L648.922 540.355H645.078C639.835 540.708 635.641 538.94 635.99 537.172V537.172Z" fill="#FCD224"/>
                        <path id="Vector_179" d="M648.922 540.708L649.272 534.343H645.777C644.379 534.343 643.33 534.343 642.282 534.697C641.233 534.697 640.184 535.05 639.136 535.404C637.388 536.111 636.34 536.818 635.99 537.526C635.641 539.294 639.835 540.708 645.427 540.708H648.922V540.708Z" fill="url(#paint92_linear)"/>
                        <path id="Vector_180" d="M656.961 550.61H666.398C671.99 550.61 676.534 548.842 676.184 547.074C676.184 546.013 674.087 544.952 671.291 544.245C669.544 543.891 667.796 543.538 665.699 543.538H656.612L656.961 550.61Z" fill="url(#paint93_linear)"/>
                        <g id="Group_6" opacity="0.1">
                            <path id="Vector_181" opacity="0.1" d="M683.175 547.074C721.781 547.074 753.078 535.199 753.078 520.551C753.078 505.903 721.781 494.029 683.175 494.029C644.568 494.029 613.272 505.903 613.272 520.551C613.272 535.199 644.568 547.074 683.175 547.074Z" fill="black"/>
                            <path id="Vector_182" opacity="0.1" d="M683.175 536.111C712.515 536.111 736.301 528.67 736.301 519.49C736.301 510.311 712.515 502.869 683.175 502.869C653.834 502.869 630.049 510.311 630.049 519.49C630.049 528.67 653.834 536.111 683.175 536.111Z" fill="black"/>
                            <path id="Vector_183" opacity="0.1" d="M683.175 506.406C710.786 506.406 733.155 512.771 735.951 521.259C736.301 520.551 736.301 520.198 736.301 519.49C736.301 510.296 712.534 502.869 683.175 502.869C653.815 502.869 630.049 510.296 630.049 519.49C630.049 520.198 630.048 520.551 630.398 521.259C633.194 513.125 655.563 506.406 683.175 506.406V506.406Z" fill="black"/>
                            <path id="Vector_184" opacity="0.1" d="M613.272 542.83C613.272 557.329 644.728 569.353 683.175 569.353C721.621 569.353 753.078 557.329 753.078 542.83V520.551C753.078 535.05 721.621 547.074 683.175 547.074C644.728 547.074 613.272 535.05 613.272 520.551V542.83Z" fill="black"/>
                            <path id="Vector_185" opacity="0.1" d="M658.01 545.306C631.796 541.416 613.272 531.868 613.272 520.551V542.83C613.272 554.147 631.796 563.695 658.01 567.585V545.306Z" fill="black"/>
                            <path id="Vector_186" opacity="0.1" d="M683.175 547.074H674.786V569.353H683.175V547.074Z" fill="black"/>
                            <path id="Vector_187" opacity="0.1" d="M713.582 523.027V521.966C713.233 519.49 710.437 517.722 706.942 516.308V512.771V512.064C706.243 507.82 697.505 505.699 689.466 505.699H686.32V504.638C686.32 503.93 685.621 503.223 684.922 503.223H677.582C676.883 503.223 676.184 503.93 676.184 504.638V505.699H674.087C665.349 505.699 655.913 508.174 655.214 512.418V513.125V517.015C655.214 517.722 655.214 518.783 656.262 519.844C656.612 520.551 657.311 520.905 658.359 521.259H655.214H654.864H654.515V521.612V521.966V522.319V523.027V526.917C654.515 527.624 654.515 528.685 655.563 529.746C657.66 532.221 663.602 533.989 670.592 533.989H676.534V536.465C676.534 537.172 677.233 537.879 677.932 537.879H686.67C687.369 537.879 688.068 537.172 688.068 536.465V533.989H696.456C704.845 533.989 714.631 531.868 715.68 526.917V526.563C713.582 527.624 713.582 526.563 713.582 523.027V523.027ZM669.194 525.856H676.534L676.883 518.783H673.039C663.252 518.783 655.563 515.954 656.262 512.771C656.961 509.589 664.65 507.113 673.738 507.113H677.233V504.638H684.573V507.113H689.116C697.505 507.113 704.845 509.589 705.544 512.418H697.854C697.854 511.71 697.155 511.357 696.456 511.003C695.058 510.296 692.262 509.589 689.466 509.589H684.922V515.954H694.01C703.796 515.954 712.184 518.783 712.534 522.319C713.233 525.856 705.194 529.039 694.709 529.039H684.922V532.928H676.184V529.039H668.845C660.456 529.039 653.816 526.563 654.165 523.38H662.204C662.204 524.795 665.349 525.856 669.194 525.856V525.856Z" fill="black"/>
                            <path id="Vector_188" opacity="0.1" d="M654.165 523.38C653.816 526.563 660.107 529.039 668.845 529.039H676.184V532.929H684.922V529.039H694.709C705.194 529.039 713.233 525.856 712.534 522.319C711.835 518.783 703.447 515.954 694.01 515.954H684.922V509.589H689.466C692.262 509.589 695.058 509.942 696.456 511.003C697.505 511.357 697.854 512.064 697.854 512.418H705.544C705.194 509.589 697.854 507.113 689.117 507.113H684.573V504.638H677.233V507.113H673.738C664.651 507.113 656.612 509.589 656.262 512.771C655.563 515.954 663.252 518.783 673.039 518.783H676.884L676.534 525.856H669.194C665.35 525.856 662.204 524.795 662.553 523.38H654.165V523.38ZM699.252 519.49C702.049 520.198 703.796 521.259 704.146 522.319C704.495 524.441 699.951 525.856 694.359 525.856H684.922V518.783H694.01C695.757 518.783 697.505 519.137 699.252 519.49ZM663.602 512.771C663.602 512.064 665 511.003 666.748 510.649C667.796 510.296 668.495 510.296 669.893 509.942C670.942 509.942 672.34 509.589 673.388 509.589H676.884L676.534 515.954H672.689C667.447 515.954 663.252 514.539 663.602 512.771V512.771Z" fill="black"/>
                            <path id="Vector_189" opacity="0.1" d="M676.883 515.954L677.233 509.589H673.738C672.34 509.589 671.291 509.589 670.243 509.942C669.194 509.942 668.146 510.296 667.097 510.649C665.35 511.357 664.301 512.064 663.951 512.771C663.602 514.539 667.796 515.954 673.388 515.954H676.883V515.954Z" fill="black"/>
                            <path id="Vector_190" opacity="0.1" d="M684.573 525.856H694.01C699.602 525.856 704.146 524.088 703.796 522.319C703.796 521.259 701.699 520.198 698.903 519.49C697.155 519.137 695.408 518.783 693.311 518.783H684.223L684.573 525.856Z" fill="black"/>
                        </g>
                        <path id="Vector_191" d="M683.175 543.538C721.781 543.538 753.078 531.663 753.078 517.015C753.078 502.367 721.781 490.492 683.175 490.492C644.568 490.492 613.272 502.367 613.272 517.015C613.272 531.663 644.568 543.538 683.175 543.538Z" fill="url(#paint94_linear)"/>
                        <path id="Vector_192" d="M683.175 532.575C712.515 532.575 736.301 525.133 736.301 515.954C736.301 506.775 712.515 499.333 683.175 499.333C653.834 499.333 630.049 506.775 630.049 515.954C630.049 525.133 653.834 532.575 683.175 532.575Z" fill="url(#paint95_linear)"/>
                        <path id="Vector_193" d="M683.175 502.869C710.786 502.869 733.155 509.235 735.951 517.722C736.301 517.015 736.301 516.661 736.301 515.954C736.301 506.759 712.534 499.333 683.175 499.333C653.815 499.333 630.049 506.759 630.049 515.954C630.049 516.661 630.048 517.015 630.398 517.722C633.194 509.589 655.563 502.869 683.175 502.869V502.869Z" fill="url(#paint96_linear)"/>
                        <path id="Vector_194" d="M613.272 539.294C613.272 553.793 644.728 565.817 683.175 565.817C721.621 565.817 753.078 553.793 753.078 539.294V517.015C753.078 531.514 721.621 543.538 683.175 543.538C644.728 543.538 613.272 531.514 613.272 517.015V539.294Z" fill="url(#paint97_linear)"/>
                        <path id="Vector_195" d="M658.01 541.769C631.796 537.879 613.272 528.331 613.272 517.015V539.294C613.272 550.61 631.796 560.158 658.01 564.048V541.769Z" fill="url(#paint98_linear)"/>
                        <path id="Vector_196" d="M683.175 543.538H674.786V565.817H683.175V543.538Z" fill="url(#paint99_linear)"/>
                        <path id="Vector_197" d="M713.582 519.49V518.429C713.233 515.954 710.437 514.186 706.942 512.771V509.235V508.528C706.243 504.284 697.505 502.162 689.466 502.162H686.32V501.101C686.32 500.394 685.621 499.687 684.922 499.687H677.582C676.883 499.687 676.184 500.394 676.184 501.101V502.162H674.087C665.349 502.162 655.913 504.638 655.214 508.881V509.589V513.478C655.214 514.186 655.214 515.247 656.262 516.308C656.612 517.015 657.311 517.368 658.359 517.722H655.214H654.864H654.515V518.076V518.429V518.783V519.49V523.38C654.515 524.088 654.515 525.148 655.563 526.209C657.66 528.685 663.602 530.453 670.592 530.453H676.534V532.928C676.534 533.636 677.233 534.343 677.932 534.343H686.67C687.369 534.343 688.068 533.636 688.068 532.928V530.453H696.456C704.845 530.453 714.631 528.331 715.68 523.38V523.027C713.582 524.088 713.582 523.027 713.582 519.49V519.49ZM669.194 522.319H676.534L676.883 515.247H673.039C663.252 515.247 655.563 512.418 656.262 509.235C656.961 506.052 664.65 503.577 673.738 503.577H677.233V501.101H684.573V503.577H689.116C697.505 503.577 704.845 506.052 705.544 508.881H697.854C697.854 508.174 697.155 507.82 696.456 507.467C695.058 506.759 692.262 506.052 689.466 506.052H684.922V512.418H694.01C703.796 512.418 712.184 515.247 712.534 518.783C713.233 522.319 705.194 525.502 694.709 525.502H684.922V529.392H676.184V525.502H668.845C660.456 525.502 653.816 523.027 654.165 519.844H662.204C662.204 521.258 665.349 522.319 669.194 522.319V522.319Z" fill="url(#paint100_linear)"/>
                        <path id="Vector_198" d="M654.165 519.844C653.816 523.027 660.107 525.502 668.845 525.502H676.184V529.392H684.922V525.502H694.709C705.194 525.502 713.233 522.319 712.534 518.783C711.835 515.247 703.447 512.418 694.01 512.418H684.922V506.052H689.466C692.262 506.052 695.058 506.406 696.456 507.467C697.505 507.82 697.854 508.528 697.854 508.881H705.544C705.194 506.052 697.854 503.577 689.117 503.577H684.573V501.101H677.233V503.577H673.738C664.651 503.577 656.612 506.052 656.262 509.235C655.563 512.418 663.252 515.247 673.039 515.247H676.884L676.534 522.319H669.194C665.35 522.319 662.204 521.259 662.553 519.844H654.165V519.844ZM699.252 515.954C702.049 516.661 703.796 517.722 704.146 518.783C704.495 520.905 699.951 522.319 694.359 522.319H684.922V515.247H694.01C695.757 515.247 697.505 515.6 699.252 515.954ZM663.602 509.235C663.602 508.528 665 507.467 666.748 507.113C667.796 506.759 668.495 506.759 669.893 506.406C670.942 506.406 672.34 506.052 673.388 506.052H676.884L676.534 512.418H672.689C667.447 512.418 663.252 511.003 663.602 509.235V509.235Z" fill="#FCD224"/>
                        <path id="Vector_199" d="M676.883 512.418L677.233 506.052H673.738C672.34 506.052 671.291 506.052 670.243 506.406C669.194 506.406 668.146 506.759 667.097 507.113C665.35 507.82 664.301 508.528 663.951 509.235C663.602 511.003 667.796 512.418 673.388 512.418H676.883V512.418Z" fill="url(#paint101_linear)"/>
                        <path id="Vector_200" d="M684.573 522.319H694.01C699.602 522.319 704.146 520.551 703.796 518.783C703.796 517.722 701.699 516.661 698.903 515.954C697.155 515.6 695.408 515.247 693.311 515.247H684.223L684.573 522.319Z" fill="url(#paint102_linear)"/>
                    </g>
                </g>
                <defs>
                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                        <use xlinkHref="#image0"/>
                    </pattern>
                    <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                        <use xlinkHref="#image1"/>
                    </pattern>
                    <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
                        <use xlinkHref="#image0"/>
                    </pattern>
                    <linearGradient id="paint0_linear" x1="707.781" y1="291.947" x2="707.781" y2="19.0991" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#018C99"/>
                        <stop offset="0.2349" stop-color="#30A1AA"/>
                        <stop offset="0.6003" stop-color="#74C0C2"/>
                        <stop offset="0.8651" stop-color="#9FD4D1"/>
                        <stop offset="1" stop-color="#AFDBD7"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="190.674" y1="291.947" x2="190.674" y2="19.0991" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#018C99"/>
                        <stop offset="0.2349" stop-color="#30A1AA"/>
                        <stop offset="0.6003" stop-color="#74C0C2"/>
                        <stop offset="0.8651" stop-color="#9FD4D1"/>
                        <stop offset="1" stop-color="#AFDBD7"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear" x1="218.705" y1="437.306" x2="218.705" y2="-80.8235" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear" x1="606.701" y1="291.947" x2="606.701" y2="19.0991" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#018C99"/>
                        <stop offset="0.2349" stop-color="#30A1AA"/>
                        <stop offset="0.6003" stop-color="#74C0C2"/>
                        <stop offset="0.8651" stop-color="#9FD4D1"/>
                        <stop offset="1" stop-color="#AFDBD7"/>
                    </linearGradient>
                    <linearGradient id="paint4_linear" x1="698.309" y1="437.305" x2="698.309" y2="-80.8231" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint5_linear" x1="312.689" y1="273.717" x2="312.689" y2="0.868175" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#018C99"/>
                        <stop offset="0.2349" stop-color="#30A1AA"/>
                        <stop offset="0.6003" stop-color="#74C0C2"/>
                        <stop offset="0.8651" stop-color="#9FD4D1"/>
                        <stop offset="1" stop-color="#AFDBD7"/>
                    </linearGradient>
                    <linearGradient id="paint6_linear" x1="446.134" y1="437.305" x2="446.134" y2="-80.8234" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <radialGradient id="paint7_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(474.29 608.155) scale(385.541 87.1409)">
                        <stop stop-color="#052845"/>
                        <stop offset="0.2973" stop-color="#A8B9C8" stop-opacity="0.7027"/>
                        <stop offset="0.4964" stop-color="#B1C0CD" stop-opacity="0.5036"/>
                        <stop offset="0.6667" stop-color="#C0CDD7" stop-opacity="0.3333"/>
                        <stop offset="0.8209" stop-color="#D5DEE5" stop-opacity="0.1791"/>
                        <stop offset="0.9628" stop-color="#F0F4F7" stop-opacity="0.037157"/>
                        <stop offset="1" stop-color="#F9FBFC" stop-opacity="0"/>
                    </radialGradient>
                    <linearGradient id="paint8_linear" x1="243.412" y1="347.384" x2="363.168" y2="405.969" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#AFDBD7"/>
                        <stop offset="0.1349" stop-color="#9FD4D1"/>
                        <stop offset="0.3997" stop-color="#74C0C2"/>
                        <stop offset="0.7651" stop-color="#30A1AA"/>
                        <stop offset="1" stop-color="#018C99"/>
                    </linearGradient>
                    <linearGradient id="paint9_linear" x1="243.387" y1="394.582" x2="304.111" y2="376.738" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#AFDBD7"/>
                        <stop offset="0.1349" stop-color="#9FD4D1"/>
                        <stop offset="0.3997" stop-color="#74C0C2"/>
                        <stop offset="0.7651" stop-color="#30A1AA"/>
                        <stop offset="1" stop-color="#018C99"/>
                    </linearGradient>
                    <linearGradient id="paint10_linear" x1="126.874" y1="453.234" x2="398.384" y2="427.833" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#AFDBD7"/>
                        <stop offset="0.1349" stop-color="#9FD4D1"/>
                        <stop offset="0.3997" stop-color="#74C0C2"/>
                        <stop offset="0.7651" stop-color="#30A1AA"/>
                        <stop offset="1" stop-color="#018C99"/>
                    </linearGradient>
                    <linearGradient id="paint11_linear" x1="177.146" y1="535.537" x2="264.484" y2="438.64" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#AFDBD7"/>
                        <stop offset="0.1349" stop-color="#9FD4D1"/>
                        <stop offset="0.3997" stop-color="#74C0C2"/>
                        <stop offset="0.7651" stop-color="#30A1AA"/>
                        <stop offset="1" stop-color="#018C99"/>
                    </linearGradient>
                    <linearGradient id="paint12_linear" x1="675.755" y1="225.164" x2="706.926" y2="490.012" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#AFDBD7"/>
                        <stop offset="0.1349" stop-color="#9FD4D1"/>
                        <stop offset="0.3997" stop-color="#74C0C2"/>
                        <stop offset="0.7651" stop-color="#30A1AA"/>
                        <stop offset="1" stop-color="#018C99"/>
                    </linearGradient>
                    <linearGradient id="paint13_linear" x1="756.153" y1="533.919" x2="643.874" y2="321.914" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#018C99"/>
                        <stop offset="0.2349" stop-color="#30A1AA"/>
                        <stop offset="0.6003" stop-color="#74C0C2"/>
                        <stop offset="0.8651" stop-color="#9FD4D1"/>
                        <stop offset="1" stop-color="#AFDBD7"/>
                    </linearGradient>
                    <linearGradient id="paint14_linear" x1="790.571" y1="348.62" x2="700.528" y2="503.115" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#AFDBD7"/>
                        <stop offset="0.1349" stop-color="#9FD4D1"/>
                        <stop offset="0.3997" stop-color="#74C0C2"/>
                        <stop offset="0.7651" stop-color="#30A1AA"/>
                        <stop offset="1" stop-color="#018C99"/>
                    </linearGradient>
                    <linearGradient id="paint15_linear" x1="696.545" y1="438.765" x2="715.863" y2="521.762" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#AFDBD7"/>
                        <stop offset="0.1349" stop-color="#9FD4D1"/>
                        <stop offset="0.3997" stop-color="#74C0C2"/>
                        <stop offset="0.7651" stop-color="#30A1AA"/>
                        <stop offset="1" stop-color="#018C99"/>
                    </linearGradient>
                    <linearGradient id="paint16_linear" x1="261.381" y1="561.078" x2="261.381" y2="625.439" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FCD224"/>
                        <stop offset="1" stop-color="#FFA340"/>
                    </linearGradient>
                    <linearGradient id="paint17_linear" x1="261.381" y1="577.027" x2="261.381" y2="609.915" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint18_linear" x1="261.381" y1="574.021" x2="261.381" y2="604.292" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint19_linear" x1="261.381" y1="643.51" x2="261.381" y2="594.709" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint20_linear" x1="213.812" y1="642.732" x2="213.812" y2="608.854" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint21_linear" x1="257.116" y1="656.665" x2="257.116" y2="617.836" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint22_linear" x1="261.276" y1="621.16" x2="261.276" y2="559.769" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint23_linear" x1="248.448" y1="582.331" x2="248.448" y2="616.917" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint24_linear" x1="272.46" y1="582.331" x2="272.46" y2="616.917" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint25_linear" x1="268.371" y1="533.423" x2="268.371" y2="597.75" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FCD224"/>
                        <stop offset="1" stop-color="#FFA340"/>
                    </linearGradient>
                    <linearGradient id="paint26_linear" x1="268.371" y1="549.337" x2="268.371" y2="582.225" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint27_linear" x1="268.371" y1="546.367" x2="268.371" y2="576.638" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint28_linear" x1="268.371" y1="615.821" x2="268.371" y2="567.019" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint29_linear" x1="220.802" y1="615.043" x2="220.802" y2="581.164" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint30_linear" x1="264.107" y1="629.011" x2="264.107" y2="590.147" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint31_linear" x1="268.266" y1="593.471" x2="268.266" y2="532.08" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint32_linear" x1="255.439" y1="554.642" x2="255.439" y2="589.227" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint33_linear" x1="279.45" y1="554.642" x2="279.45" y2="589.227" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint34_linear" x1="243.905" y1="503.824" x2="243.905" y2="568.186" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FCD224"/>
                        <stop offset="1" stop-color="#FFA340"/>
                    </linearGradient>
                    <linearGradient id="paint35_linear" x1="243.905" y1="519.773" x2="243.905" y2="552.626" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint36_linear" x1="243.905" y1="516.767" x2="243.905" y2="547.038" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint37_linear" x1="243.905" y1="586.257" x2="243.905" y2="537.455" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint38_linear" x1="196.336" y1="585.479" x2="196.336" y2="551.6" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint39_linear" x1="239.641" y1="599.412" x2="239.641" y2="560.547" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint40_linear" x1="243.8" y1="563.907" x2="243.8" y2="502.481" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint41_linear" x1="230.973" y1="525.078" x2="230.973" y2="559.663" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint42_linear" x1="254.984" y1="525.078" x2="254.984" y2="559.663" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint43_linear" x1="662.518" y1="477.549" x2="341.244" y2="331.915" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3C2E5B"/>
                        <stop offset="1" stop-color="#4328AD"/>
                    </linearGradient>
                    <linearGradient id="paint44_linear" x1="480.631" y1="361.203" x2="480.631" y2="421.781" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0013277" stop-color="#4328AD"/>
                        <stop offset="1" stop-color="#9E97FF"/>
                    </linearGradient>
                    <linearGradient id="paint45_linear" x1="460.326" y1="615.434" x2="472.015" y2="226.599" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#408462"/>
                        <stop offset="1" stop-color="#8BD6B5"/>
                    </linearGradient>
                    <linearGradient id="paint46_linear" x1="389.159" y1="418.706" x2="374.066" y2="418.262" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0013277" stop-color="#1D262D"/>
                        <stop offset="1" stop-color="#3C2E5B" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint47_linear" x1="487.983" y1="597.766" x2="500.262" y2="208.97" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#408462"/>
                        <stop offset="1" stop-color="#8BD6B5"/>
                    </linearGradient>
                    <linearGradient id="paint48_linear" x1="417.358" y1="400.928" x2="403.593" y2="400.504" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0013277" stop-color="#1D262D"/>
                        <stop offset="1" stop-color="#3C2E5B" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint49_linear" x1="508.779" y1="569.109" x2="535.737" y2="181.048" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#408462"/>
                        <stop offset="1" stop-color="#8BD6B5"/>
                    </linearGradient>
                    <linearGradient id="paint50_linear" x1="520.056" y1="404.276" x2="523.097" y2="358.485" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0013277" stop-color="#1D262D"/>
                        <stop offset="1" stop-color="#3C2E5B" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint51_linear" x1="470.25" y1="658.469" x2="470.25" y2="384.296" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0013277" stop-color="#4328AD"/>
                        <stop offset="1" stop-color="#9E97FF"/>
                    </linearGradient>
                    <linearGradient id="paint52_linear" x1="343.866" y1="575.188" x2="343.866" y2="504.39" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0013277" stop-color="#1D262D"/>
                        <stop offset="1" stop-color="#3C2E5B" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint53_linear" x1="338.623" y1="571.651" x2="338.623" y2="493.781" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0013277" stop-color="#4328AD"/>
                        <stop offset="1" stop-color="#9E97FF"/>
                    </linearGradient>
                    <linearGradient id="paint54_linear" x1="340.371" y1="512.63" x2="340.371" y2="489.962" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#F9FBFC" stop-opacity="0"/>
                        <stop offset="1" stop-color="#9E97FF"/>
                    </linearGradient>
                    <linearGradient id="paint55_linear" x1="357.183" y1="527.695" x2="357.183" y2="557.258" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3C2E5B" stop-opacity="0"/>
                        <stop offset="0.9987" stop-color="#1D262D"/>
                    </linearGradient>
                    <linearGradient id="paint56_linear" x1="353.687" y1="524.158" x2="353.687" y2="553.722" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#4328AD"/>
                        <stop offset="1" stop-color="#3C2E5E"/>
                    </linearGradient>
                    <linearGradient id="paint57_linear" x1="353.687" y1="527.447" x2="353.687" y2="549.62" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3C2E5B"/>
                        <stop offset="0.9987" stop-color="#4328AD"/>
                    </linearGradient>
                    <linearGradient id="paint58_linear" x1="679.994" y1="596.441" x2="679.994" y2="660.803" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FCD224"/>
                        <stop offset="1" stop-color="#FFA340"/>
                    </linearGradient>
                    <linearGradient id="paint59_linear" x1="679.994" y1="612.39" x2="679.994" y2="645.278" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint60_linear" x1="679.994" y1="609.384" x2="679.994" y2="639.656" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint61_linear" x1="679.994" y1="678.874" x2="679.994" y2="630.072" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint62_linear" x1="632.46" y1="678.096" x2="632.46" y2="644.218" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint63_linear" x1="675.73" y1="692.029" x2="675.73" y2="653.2" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint64_linear" x1="679.924" y1="656.524" x2="679.924" y2="595.133" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint65_linear" x1="667.097" y1="617.695" x2="667.097" y2="652.28" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint66_linear" x1="691.074" y1="617.695" x2="691.074" y2="652.281" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint67_linear" x1="686.984" y1="568.787" x2="686.984" y2="633.113" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FCD224"/>
                        <stop offset="1" stop-color="#FFA340"/>
                    </linearGradient>
                    <linearGradient id="paint68_linear" x1="686.984" y1="584.701" x2="686.984" y2="617.589" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint69_linear" x1="686.984" y1="581.73" x2="686.984" y2="612.001" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint70_linear" x1="686.984" y1="651.184" x2="686.984" y2="602.382" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint71_linear" x1="639.45" y1="650.406" x2="639.45" y2="616.528" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint72_linear" x1="682.72" y1="664.375" x2="682.72" y2="625.51" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint73_linear" x1="686.915" y1="628.834" x2="686.915" y2="567.443" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint74_linear" x1="674.087" y1="590.005" x2="674.087" y2="624.591" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint75_linear" x1="698.064" y1="590.005" x2="698.064" y2="624.591" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint76_linear" x1="679.994" y1="539.188" x2="679.994" y2="603.549" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FCD224"/>
                        <stop offset="1" stop-color="#FFA340"/>
                    </linearGradient>
                    <linearGradient id="paint77_linear" x1="679.994" y1="555.137" x2="679.994" y2="587.989" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint78_linear" x1="679.994" y1="552.131" x2="679.994" y2="582.402" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint79_linear" x1="679.994" y1="621.62" x2="679.994" y2="572.818" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint80_linear" x1="632.46" y1="620.842" x2="632.46" y2="586.964" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint81_linear" x1="675.73" y1="634.775" x2="675.73" y2="595.911" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint82_linear" x1="679.924" y1="599.27" x2="679.924" y2="537.844" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint83_linear" x1="667.097" y1="560.441" x2="667.097" y2="595.027" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint84_linear" x1="691.074" y1="560.441" x2="691.074" y2="595.027" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint85_linear" x1="655.528" y1="511.498" x2="655.528" y2="575.86" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FCD224"/>
                        <stop offset="1" stop-color="#FFA340"/>
                    </linearGradient>
                    <linearGradient id="paint86_linear" x1="655.528" y1="527.447" x2="655.528" y2="560.335" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint87_linear" x1="655.528" y1="524.441" x2="655.528" y2="554.712" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint88_linear" x1="655.528" y1="593.931" x2="655.528" y2="545.129" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint89_linear" x1="607.994" y1="593.153" x2="607.994" y2="559.274" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint90_linear" x1="651.264" y1="607.086" x2="651.264" y2="568.257" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint91_linear" x1="655.458" y1="571.581" x2="655.458" y2="510.19" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint92_linear" x1="642.631" y1="532.752" x2="642.631" y2="567.337" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint93_linear" x1="666.608" y1="532.752" x2="666.608" y2="567.337" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint94_linear" x1="683.245" y1="483.49" x2="683.245" y2="547.817" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FCD224"/>
                        <stop offset="1" stop-color="#FFA340"/>
                    </linearGradient>
                    <linearGradient id="paint95_linear" x1="683.245" y1="499.404" x2="683.245" y2="532.292" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint96_linear" x1="683.245" y1="496.433" x2="683.245" y2="526.704" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint97_linear" x1="683.245" y1="565.923" x2="683.245" y2="517.121" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224"/>
                    </linearGradient>
                    <linearGradient id="paint98_linear" x1="635.676" y1="565.145" x2="635.676" y2="531.231" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint99_linear" x1="678.981" y1="579.078" x2="678.981" y2="540.213" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint100_linear" x1="683.14" y1="543.573" x2="683.14" y2="482.146" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint101_linear" x1="670.313" y1="504.744" x2="670.313" y2="539.294" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint102_linear" x1="694.289" y1="504.744" x2="694.289" y2="539.294" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFA340"/>
                        <stop offset="1" stop-color="#FCD224" stop-opacity="0"/>
                    </linearGradient>
                    <image id="image0" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAcCAYAAADxyeavAAAACXBIWXMAAAsSAAALEgHS3X78AAAA"/>
                    <image id="image1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGwAAABUCAYAAABnT9INAAAACXBIWXMAAAsSAAALEgHS3X78AAAA"/>
                </defs>
            </svg>

    )}
}