import React, {Component} from 'react';
import "../styles/footer.css";

class Footer extends Component{
 render(){
     return(
        <div className="footer"> Helping Shanzid buy a cup of coffee! <span role="img" aria-label="coffee">☕</span></div>
     );
 }
}
export default Footer;